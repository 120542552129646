import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dots from './assets/dnddots.svg'
import items from './timmings.json'


export default class AgregarItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user ? this.props.user : JSON.parse(localStorage.user),
            eventSelected: this.props.eventSelected ? this.props.eventSelected : 0,
            items: items || []
        }
    }

    // Al finalizar DnD crea nuevo item en el array de timmings

    onDragEnd = async (result) => {
        const { source, destination } = result;
        
        if(destination && destination.droppableId==="5") {   
            if(source && this.state.items[source.index]) {
                await this.props.crearDnd(this.state.items[source.index])
                this.props.getTimeline()
            }
        }
        if (!destination) {
            return;
        }
    }

    render() {
        const { user, eventSelected } = this.state

        return (
            <div className="my-5">
                <div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        {/* Bloque donde dropear los items */}
                        <Droppable type="1" key={5} droppableId={`${5}`} direction="horizontal">
                            {(provided, snapshot) => (
                                <div className="position-relative">
                                    <div
                                    ref={provided.innerRef}
                                    style={getListStyle2(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                    >
                                        {provided.placeholder}
                                    </div>
                                    <div className="position-absolute bloque-drop" > 
                                        <h5 className="m-0">
                                            Mueva aquí los bloques para el timming
                                        </h5>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        {/* // Bloque donde dropear los items // */}

                        {/* Bloque de items con tiempos defaults */}
                        <Droppable type="1" key={1} droppableId={`${1}`} direction="horizontal" ignoreContainerClipping={true}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} >

                                    {/* iteración de caja draggeable de los items */}
                                    {this.state.items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index} >
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={"item-dnd back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-around",
                                                        color: "black"
                                                    }}
                                                >
                                                    {item.name}
                                                    <img src={dots} className="ms-1 puntos" alt="puntos DnD" />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                    ))}
                                    {/*  // iteración de caja draggeable de los items // */}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {/* // Bloque de items con tiempos defaults // */}

                    </DragDropContext>
                </div>
            </div>
        );
    }
}




const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => {
    return ({
    userSelect: 'none',
    padding: `${grid}px ${grid*3}px `,
    position: 'relative',
    zIndex: 0,
    cursor: isDragging ? 'grabbing' : 'grab',
    ...draggableStyle,
})};
  
const getListStyle = isDraggingOver => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${grid*2}px`,
    justifyContent: 'center',
    overflow: 'hidden',
});

const getListStyle2 = isDraggingOver => ({
    background: isDraggingOver ? '#FE00FF' : 'lightgrey',
    borderRadius: "10px",
    display: 'flex',
    padding: "0 40%",
    marginBottom: "2em",
    justifyContent: 'center',
    minHeight: '100px',
    overflow: 'hidden',
});
