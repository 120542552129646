import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import urlServer from './server'
import { Link } from 'react-router-dom'
// import Spinner from './spinner'

import brandLogo from './assets/brandlogo.png'
import usuarioSVG from './assets/usuario.svg'
import candadoSVG from './assets/candado.svg'
import ojopassword from './assets/ojopassword.svg'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from "react-router-dom";

const MySwal = withReactContent(Swal)

function withParams(Component) {
    return props => <Component 
                    {...props} 
                    params={useParams()} />;
}

class Restablecer extends Component {
    constructor(props){
        super(props);
        this.state={
            email: '',
            password1:'',
            password2:'',
            loading: true,
            alert: [],
            successRecover: false,
            params: new URLSearchParams(window.location.search),
        }
        this.submit = this.submit.bind(this)
    }

    // Validador del usuario y contraseña

    validateReq = (data) => {
        let alert = []
        let valid = true
        if (!data.email) {
            alert = [
                ...alert,
                'Debe ingresar un e-mail',
            ]
            valid = false
        }
        if (!valid) {
            this.setState({
                alert
            })
            let string = alert.map(al => {
                return al + "<br />"
            })
            MySwal.fire({
                icon: 'error',
                title: string ? string : "Ha ocurrido un error.",
                showConfirmButton: false,
                timer: 1500
            })
        }
        return valid
    }

    //SUBMIT RECUPERACIÓN
    async submit(event){
        this.setState({
            loading: true
        })
        const { password1, password2 } = this.state
        if(password1 !== password2){
            MySwal.fire({
                icon: 'error',
                title: 'Las contraseñas no coinciden',
                showConfirmButton: false,
                timer: 3500
            })
            return false
        }
        let data = {
            hash: this.props.params.hash || this.state.params.get('hash'),
            email: this.state.email,
            newPass: password1,
        }
        if(this.validateReq(data)) {
            const apiBaseUrl = urlServer + `/api/setUserPassForgotpass?hash=${data.hash}&email=${data.email}&newPass=${data.newPass}`
            await fetch(apiBaseUrl)
            .then(async response => {
                if (parseInt(response.status) === 200) {
                    return response.json()
                } else {
                    let err = await response.json()
                    err.status = response.status
                    throw err
                }
            })
            .then(async result => {
                if(result.indexOf('incorrect') === -1) {
                    this.setState({
                        loading: false
                    })
                    MySwal.fire(
                        '¡Listo!',
                        'Su contraseña ha sido restablecida',
                        'success'
                    )
                    .then(e => {
                        window.location.href = window.location.origin
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    MySwal.fire({
                        icon: 'error',
                        title: typeof(result) === "string" ? result : "Ha ocurrido un error.",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(error => {
                MySwal.fire({
                    icon: 'error',
                    title: error.message ? error.message : "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500
                })
                console.log('error', error); 
                this.setState({
                    loading: false
                })
            });
        }
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.setState({
            loading: false,
        })
        const hash = this.state.params.get('hash')
    }
    

    cambiarTipoInput = (input) => {
        input.type = input.type === "password" ? "text" : "password"
    }

    render() {

        return (
                        <React.Fragment>

                            <div className="login-title" >
                                    <img className="brand-logo mb-5" src={brandLogo} alt="brand-logo" ></img>
                                    <div className="f-16" style={{marginBottom: "40px", lineHeight: "20px"}}>
                                        Complete los campos para recibir un correo de recuperación de contraseña. 
                                    </div>
                            </div>
                            
                            {/* BLOQUE DE LOGIN */}
                            {/* {loading ?
                            <div style={{minHeight: '100px'}}>
                                <Spinner />
                            </div>
                            :
                            } */}
                            <div id="">

                                <div id="warning" className="alert alert-danger" role="alert" style={{display: "none"}}>
                                        Las contraseñas deben coincidir.
                                </div>
                                <div className="theme-input col-9 d-flex">
                                    <ReactSVG src={usuarioSVG} className="px-2 svg" />
                                    <input type="email" id="email" name="email" className='theme-input-box' value={this.state.email} placeholder={"E-mail"} autoComplete='off' onChange={(e) => this.setState({ email: e.target.value })} />
                                </div>

                                <div className="theme-input col-9 d-flex align-items-center">
                                    <ReactSVG src={candadoSVG} className="px-2 svg" />
                                    <input id="password1" name="password1" type="password" className='theme-input-box' value={this.state.password1} placeholder="Nueva contraseña" autoComplete='off' onChange = {(e) => this.setState({ password1: e.target.value })} />
                                    <ReactSVG src={ojopassword} className="px-2 svg" style={{cursor: "pointer"}}  onClick={e => {
                                        const input = document.getElementById('password1')
                                        this.cambiarTipoInput(input)
                                    }} />
                                </div>

                                <div className="theme-input col-9 d-flex align-items-center">
                                    <ReactSVG src={candadoSVG} className="px-2 svg" />
                                    <input id="password2" name="password2" type="password" className='theme-input-box' value={this.state.password2} placeholder="Confirme nueva contraseña" autoComplete='off' onChange = {(e) => this.setState({ password2: e.target.value })} />
                                    <ReactSVG src={ojopassword} className="px-2 svg" style={{cursor: "pointer"}} onClick={e => {
                                        const input = document.getElementById('password2')
                                        this.cambiarTipoInput(input)
                                    }} />
                                </div>

                                <div className="col-9 div-button" >
                                    <button className="theme-button f-14 fw-500" onClick={this.submit}>
                                    ENVIAR
                                    </button>
                                </div>
                                <hr style={{borderTopColor: 'white'}} />
                                <div className="d-flex justify-content-between remember flex-row-reverse">
                                    <Link to={`${process.env.PUBLIC_URL}/`}>
                                        <div>
                                            Volver a la pantalla de Login
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {/* // BLOQUE DE LOGIN // */}
                            
                        </React.Fragment>
        );
    }
}



export default withParams(Restablecer);