import React, { Component } from "react";
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import tick from './assets/tick_trivia.svg';
import cross from './assets/cross_trivia.svg';
import { postmanejado } from './fetchmanejado';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';


let preguntasNoRespondidas = (preguntas, scores) => {
    return preguntas.filter(preg => !scores.find(score => score && score.respuesta && score.respuesta.pregunta && score.respuesta.pregunta.id === preg.id))
}

let preguntasYaRespondidas = (preguntas, scores) => {
    return preguntas.filter(preg => scores.find(score => score && score.respuesta && score.respuesta.pregunta && score.respuesta.pregunta.id === preg.id))
}

let rtasCorrectas = (respuestas, scores) => {
    return respuestas.filter(rta => scores.find(score => (score.respuesta_id === rta.id)) && (rta.correcta === 1))
}

let formatearTiempo = (tiempo) => {
    return (
        <>
        {tiempo ? Math.floor(tiempo/3600000) : "- "}:
        <span>{tiempo ? ("0" + Math.floor((tiempo / 60000) % 60)).slice(-2) : " - - "}:</span>
        <span>{tiempo ? ("0" + Math.floor((tiempo / 1000) % 60)).slice(-2) : " - - "}</span>.
        {tiempo ? (tiempo%1000) : " - - - "}
        </>
        )
}

export default class Trivia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user || JSON.parse(localStorage.user),
            eventSelected: this.props.eventSelected || 0,
            fechaLimiteExcedida: this.props.fechaLimiteExcedida,
            iniciado: false,
            preguntas: [],
            respuestas: [],
            scores: [],
            interval: 0,
            tiempo: 0,
        };
    }

    enviarRespuesta = async (score, tiempo) => {
        let { user, scores } = this.state;
        let score_nuevo = {
            ...score,
            invitado_id: user.id,
            tiempo,
        };
        scores = [
            ...scores,
            score_nuevo
        ]
        const response = await postmanejado("/api/insertScoreInvitado", score_nuevo);
        this.setState({
            scores
        });
        return response;
    }

    getPreguntas = async (invitado_id, event_id) => {
        const response = await postmanejado("/api/getPreguntasInvitado", { invitado_id, event_id });
        return response;
    }

    iniciarConteo = () => {
        this.setState({
            tiempo: 0,
            iniciado: true,
            interval: setInterval(() => {
                let { tiempo } = this.state;
                tiempo += 10;
                this.setState({
                    tiempo
                });
            }, 10)
        })
    }

    finalizarConteo = (interval, esUltimaPregunta) => {
        clearInterval(interval)
        if(esUltimaPregunta) {
            this.setState({
                tiempo: 0,
                iniciado: false,
                interval: 0,
            })
        }
    }

    init = async () => {
        const { user, eventSelected } = this.state;
        const { preguntas, respuestas, scores } = await this.getPreguntas(user.id, user.event[eventSelected].id);
        this.setState({
            preguntas, 
            respuestas, 
            scores
        })
    }

    componentDidMount() {
        this.init()
    }

    render() {
        let { preguntas, respuestas, interval,
                iniciado, tiempo, scores } = this.state;

        let { fechaLimiteExcedida } = this.props;
        
        let preguntasRespondidas = preguntasYaRespondidas(preguntas, scores);

        let respuestasCorrectas = rtasCorrectas(respuestas, scores)

        let tiempoContando = fechaLimiteExcedida ? scores.reduce(((acc, b) => acc + b.tiempo), 0) : (scores.reduce(((acc, b) => acc + b.tiempo), 0) + tiempo)

        let triviaTerminada = fechaLimiteExcedida || !preguntasNoRespondidas(preguntas, scores).length

        return (
        <div>
            {preguntas && (

                <>
                <div className="text-center position-relative">
                    {triviaTerminada &&
                    <div>
                        TIEMPO TOTAL
                    </div>}
                    <div>
                        {(formatearTiempo(tiempoContando))}
                    </div>
                    {triviaTerminada &&
                        <div className="position-absolute my-auto" style={{top: "0", bottom: "0", right: "0"}}>
                            Correctas: 
                            {<> {respuestasCorrectas.length}/{preguntas.length}</>}
                        </div>
                    }
                </div>
                <div className="mt-5 lista-temas d-flex position-relative">
                    { iniciado || triviaTerminada ?
                    <Swiper modules={[Pagination, Navigation]} spaceBetween={50} slidesPerView={1} 
                        navigation={triviaTerminada ? true : {prevEl: ".prev", nextEl: ".next"}}
                        pagination={triviaTerminada && { clickable: true }}
                        allowTouchMove={triviaTerminada}
                        onSwiper={(swiper) => {
                            if(!fechaLimiteExcedida && preguntasRespondidas.length && preguntasRespondidas.length !== preguntas.length) {
                                swiper.slideTo(preguntasRespondidas.length, 0)
                            }
                        }}
                    >
                        {preguntas.map((preg, indexP) => {
                            let preguntaRespondida = scores.find(score => score.respuesta.pregunta.id === preg.id)
                            let esUltimaPregunta = indexP === (preguntas.length - 1);
                            let input = document.querySelector("input:checked")
                            let inputValue = parseInt(input && input.value)
                            return (
                                <SwiperSlide key={indexP} virtualIndex={indexP}>
                                    <div className="caja-pregunta">

                                        {/* Tiempo de cada pregunta */}
                                        <div className="col-12 text-center position-relative" id="tiempo">
                                            {preguntaRespondida && formatearTiempo(preguntaRespondida.tiempo)}
                                            <div className="position-absolute my-auto" style={{top: "0", bottom: "0", right: "0"}}>
                                                {(indexP+1)}/{(preguntas.length)}
                                            </div>
                                        </div>
                                        {/* Tiempo de cada pregunta */}

                                        {/* Pregunta */}
                                        <div className="col-12 text-center py-4 pregunta row align-middle align-items-center">
                                            <div className="col-11 title-pregunta text-start blue font-secondary">
                                                <strong>
                                                    {preg.pregunta}
                                                </strong>
                                            </div>
                                        </div>
                                        {/* Pregunta */}

                                        <div className="form-group text-start">
                                            {/* Listado de respuestas */}
                                            {preg.respuestas.map((rta, index) => {
                                                let contestada = rta.scores.length
                                                return (
                                                    <div className="mx-auto text-start my-2 d-flex align-items-center" key={index}>
                                                        <input name="respuestas" type="radio" className="css-checkbox" id={"respuesta_"+rta.id} 
                                                            checked={triviaTerminada ? contestada : undefined}
                                                            disabled={triviaTerminada}
                                                            value={rta.id} 
                                                            />

                                                        <label className="css-label font-secondary respuesta ps-3" htmlFor={"respuesta_"+rta.id}
                                                            style={contestada ? {fontWeight: "bold", letterSpacing: "1.5px", color: (rta.correcta ? "#32c671" : "#dd3333")} : {}}
                                                        >
                                                            {rta.respuesta}
                                                        </label>
                                                        {(contestada || fechaLimiteExcedida) &&
                                                            <img src={rta.correcta === 1 ? tick : cross} className="ms-3" style={{width: "15px", height: "15px"}} alt="correcto/incorrecto" />
                                                        }
                                                    </div>
                                                )
                                            })}
                                            {/* Listado de respuestas */}

                                            {/* Botón para continuar */}
                                            {!triviaTerminada &&
                                            <div className="col-12 text-end boton-siguiente">
                                                <button className="btn btn-dark next" 
                                                    onClick={async () => {
                                                        let tiempoTotal = scores.reduce(((acc, b) => acc + b.tiempo), 0)
                                                        let rtaAEnviar = preg.respuestas.find(rta => rta.id === inputValue)
                                                        rtaAEnviar = {
                                                            ...rtaAEnviar,
                                                            pregunta: preg
                                                        }
                                                        if(!preguntaRespondida) {
                                                            this.finalizarConteo(interval, esUltimaPregunta)
                                                            await this.enviarRespuesta({respuesta_id: rtaAEnviar.id, respuesta: rtaAEnviar}, tiempoContando - tiempoTotal);
                                                            if(esUltimaPregunta) {
                                                                await this.init();
                                                            } else {
                                                                this.iniciarConteo();
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {esUltimaPregunta ?
                                                    "Finalizar" : "Continuar"
                                                    }
                                                </button>
                                            </div>
                                            }
                                            {/* Botón para continuar */}

                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                            })}

                    </Swiper>:
                    <div className="col-12 text-center">
                        <button type="button" name="subm" id="subm" className="btn btn-dark" onClick={() => this.iniciarConteo()} >Comenzar</button>
                    </div>
                    }

                </div>
                </>
            )}
        </div>
        );
    }
}
