export const ItemAccordion = (params) => {
    const { index, titulo, body, user, eventSelected, className, show, contenidoNuevo } = params;
    
    return (
            <div className={"card p-3 tabevento " + (className || "")}>
                <div className="mt-3">
                    <div id={"flush-heading" + index}>
                        <div data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + index} aria-expanded="false" aria-controls={"flush-collapse" + index} className={"text-start accordion-button collapsed " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo") }>
                            <div className="d-flex justify-content-between w-100 pe-2">
                                <h6 className="sub">
                                    {titulo + " "} 
                                </h6>
                                {contenidoNuevo && <small className="f-13">
                                    ¡Contenido Nuevo!
                                </small>}
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"flush-collapse" + index} className={"accordion-collapse collapse " + (show ? "dont-collapse-sm" : "")} aria-labelledby={"flush-heading" + index} data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body px-2">
                        <div className="mt-3">
                            {body}
                        </div>
                    </div>
                </div>
            </div>
    )
}