import React, { Component } from 'react';
import urlServer from './server'
import { ReactSVG } from 'react-svg'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { postmanejado } from './fetchmanejado';

import descargar from './assets/descargar.svg'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

let pesoLocale = new Intl.NumberFormat('es-ES')

const sortByDate = (a, b) => {
    return new Date(b.date) - new Date(a.date);
}

export default class Calculadora extends Component {
    constructor(props) {
        super(props);
        this.state={
            user: this.props.user ? this.props.user : JSON.parse(localStorage.user),
            eventSelected: this.props.eventSelected ? this.props.eventSelected : 0,
            items: {},
            itemsNuevo: {},
            loading: true,
            tabcalculadora: false,
            tabpresupuestos: false,
            presupuestos: [],
            calcsguardadas: [],
            calcSelected: null,
            calculadoraNueva: {},
            calculadora: {},
        }
    }

    consultarCalculadora = async () => {
        const data = await this.consultarEntidad("calculators")

        if(data && data.length > 0) {
            this.setState({
                calcsguardadas: data,
            })
        }
    }

    editarTabla = async () => {
        let { calcjson, items }= this.state

        calcjson = {
            ...calcjson,
            text: JSON.stringify(items)
        }

        let data = {
            id: calcjson.id,
            axn: "update",
            fields: JSON.stringify(calcjson),
            entity: "Calculator"
        }

        const response = await postmanejado(`/api/auth/abmRowFromModel`, data, true)
        .then(res => {
            this.consultarCalculadora()
        })
        return await response
    }

    crearTabla = async () => {
        let { user, items, eventSelected }= this.state
        const json = {
            name: (new Date()).toLocaleDateString("es",{ hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric',  }),
            event_id: user.event[eventSelected].id,
            text: JSON.stringify(items),
            estado_id: 2,
        }
        let data = {
            axn: "create",
            fields: JSON.stringify(json),
            entity: "Calculator"
        }
        const response = await postmanejado(`/api/auth/abmRowFromModel`, data, true)
        .then(res => {
            this.consultarCalculadora()
        })
        return await response
    }

    guardarCalculators = async () => {
        if(this.state.calcjson) {
            await this.editarTabla()
            .then((res) => {
                return res
            })
        } else {
            await this.crearTabla()
            .then((res) => {
                return res
            })
        }
    }

    renderItems = () => {
        const { user, items, eventSelected } = this.state
        let arrayFinal = []
        let arrayIndividual = []
        let arraySelects = []
        let i=0
        for (let key in items) {
            let producto = items[key]
            producto.tieneSelected = producto.find(item => item.selected) ? true : false;
            if(producto && producto[0] && producto[0].ID_Item !== "0") {
                if(producto.length === 1) {
                    arrayIndividual = [
                        ...arrayIndividual,
                        ((
                            <div key={`item${i}`} className={"mt-5 " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                <div className="d-flex mb-4">
                                    <label className="container-sumarpuntos d-flex justify-content-between w-100"> 
                                        <h5 style={{textTransform: "uppercase"}} className={((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                            {producto[0].Descripcion}
                                        </h5>
                                        <input id={`item-${i}`} checked={producto.tieneSelected} type="checkbox" onChange={e => {
                                            if (e.target.checked) {
                                                let { items } = this.state
                                                items[key] = items[key].map((item, index) => {
                                                    item.selected = index === 0 ? true : false
                                                    return item
                                                })
                                            } else {
                                                let { items } = this.state
                                                items[key] = items[key].map((item, index) => {
                                                    item.selected = false
                                                    return item
                                                })
                                            }
                                            this.setState({
                                                items
                                            })
                                        }} />
                                        <span className="checkmark-sumarpuntos"></span>
                                        <h6>
                                            ${pesoLocale.format(producto[0].precio)}
                                        </h6>
                                    </label>
                                </div>
                            </div>))
                    ]
                } else {

                    const marks = producto;
                      
                    function valuetext(value) {
                        return `${value}$asd$`;
                    }
                    
                    function valueLabelFormat(value) {
                        return marks.find(mark => mark.value === value).precio;
                    }
                    arraySelects = [
                        ...arraySelects,
                        ((
                            <div key={`item${i}`} className={"mt-5 " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="container-sumarpuntos"> 
                                        <h5 style={{textTransform: "uppercase"}} className={((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                            {(producto.find(item => item.selected) && producto.find(item => item.selected).Descripcion) || producto[0].Descripcion}
                                        </h5>
                                        <input id={`item-${i}`} checked={producto.tieneSelected} type="checkbox" onChange={e => {
                                            if (e.target.checked) {
                                                let { items } = this.state
                                                items[key] = items[key].map((item, index) => {
                                                    item.selected = index === 0 ? true : false
                                                    return item    
                                                })
                                            } else {
                                                let { items } = this.state
                                                items[key] = items[key].map((item) => {
                                                    item.selected = false
                                                    return item
                                                })
                                            }
                                            this.setState({
                                                items
                                            })
                                        }} />
                                        <span className="checkmark-sumarpuntos"></span>
                                    </label>
                                    {/* {producto.find(item => item.selected) &&
                                    } */}
                                </div>
                                <Box className={'px-4 mt-4 w-75 mx-auto ' + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                    <Slider
                                        aria-label="Custom marks"
                                        // defaultValue={20}
                                        valueLabelFormat={valueLabelFormat}
                                        getAriaValueText={valuetext}
                                        step={1}
                                        min={1}
                                        max={producto.length}
                                        defaultValue={(producto.find(item => item.selected) && producto.find(item => item.selected).value) || producto[0].value}
                                        className={((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}
                                        valueLabelDisplay="auto"
                                        value={(producto.find(item => item.selected) && producto.find(item => item.selected).value) || producto[0].value}
                                        marks={marks}
                                        onChange={(e, value) => {
                                            let { items } = this.state
                                            items[key] = items[key].map((item, index) => {
                                                if(item.value === value) {
                                                    item.selected = true
                                                } else {
                                                    item.selected = false
                                                }
                                                return item
                                            })
                                            this.setState({
                                                items
                                            })
                                        }}
                                    />
                                </Box> 
                            </div>))
                    ]
                }
            }
            i++
        }
        arrayFinal = [...arrayIndividual, ...arraySelects]
        return(
            <React.Fragment>
                {arrayFinal}
            </React.Fragment>
        )
    }

    calcularTotal = () => {
        const { items } = this.state
        let array = []
        for (let key in items) {
            let producto = items[key]
            const selected = producto.find(e => e.selected)
            if(selected) {
                array = [
                    ...array,
                    selected
                ]
            }
        }
        let total = 0
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            total += item.precio
        }
        return pesoLocale.format(total)
    }

    renderCalculadora = () => {
        const { user, calcsguardadas, eventSelected } = this.state


        return  (<div key={0}>
                    <div className="tabevento">
                        {/* <div className={"text-start my-3 " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo") }>
                            <h6 className="sub">
                                MI SARAPURA
                            </h6>
                        </div> */}
                        <div className="row flex-column">
                            <div className="col-sm-8 mx-auto">
                                {calcsguardadas.length > 0 &&
                                    <select className="my-3 form-control" defaultValue="null" onChange={(e) => {
                                        let boolean = e.target.value !== "null"
                                        this.setState({
                                            calcjson: boolean ? calcsguardadas[e.target.value] : null,
                                            calculadora: boolean ? JSON.parse(calcsguardadas[e.target.value].text) : this.state.calculadoraNueva,
                                            items: boolean ? JSON.parse(calcsguardadas[e.target.value].text) : this.state.itemsNuevo,
                                        })
                                    }}>
                                        <option value="null">Nueva calculadora</option>
                                        { calcsguardadas.map((calc, index) => <option key={index} value={index}> {calc.name} </option> )}
                                    </select>
                                }
                            </div>
                            <div className={ "col-sm-8 mx-auto" } style={{
                            backgroundColor: "rgba(50,50,50,0.5)",
                            borderRadius: "10px"}}>
                                <div className="total-numero">
                                    <h4>
                                        <span className={((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                            $
                                        </span>
                                    </h4>
                                    <h1>
                                        { this.calcularTotal() }
                                    </h1>
                                </div>
                                <div className="total-texto">
                                    <h6>
                                        TOTAL ESTIMADO
                                    </h6>
                                </div>
                            </div>
                            {this.renderItems()}
                            {(user.permisos.find(e => e.permiso && e.permiso.indexOf("save_calculators") !== -1)) &&
                                <div className="agregar-calculadora" onClick={async () => {
                                    MySwal.fire({
                                        title: 'Guardando calculadora',
                                        didOpen: async () => {
                                            MySwal.showLoading();
                                            await this.guardarCalculators();
                                            await this.init();
                                            MySwal.clickConfirm();
                                        },
                                    })
                                }}>
                                    Guardar
                                </div>}
                        </div>
                    </div>
                </div>)
    }

    downloadFile = async (filename) => {
        
        const data = {
            documento: filename
        }

        const response = await postmanejado("/api/getDocumentFile/", data, false, true)
        .then(async (res) => { return await res.blob(); })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.target = "_blank";
          a.download = filename;
          a.click();
        });
        return await response
    }

    renderPresupuestos = () => {
        const { user, eventSelected, presupuestos } = this.state

        return  (<div key={0}>
                    <div className="">
                        <div className={"text-start my-3 " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo") }>
                            {/* <h6 className="sub">
                                PROPUESTAS
                            </h6> */}
                            {presupuestos.length > 0 ?presupuestos.map((presu, index) => {
                                return(
                                <div key={index} className="d-flex justify-content-between align-items-center my-3 blanco py-4" style={{borderBottom: "1px solid gray"}}>
                                    <div style={{height: "fit-content"}} className="pe-4">
                                        {presu.name}
                                    </div>
                                    <div>
                                            <ReactSVG src={descargar} className="descargar-svg" onClick={() => {
                                                this.downloadFile(presu.link)
                                            }} />
                                    </div>
                                </div>)
                            }):
                            <h6 className="my-3 blanco py-4">
                                No tiene propuestas asignadas
                            </h6>
                            }
                        </div>
                    </div>
                </div>)
    }

    consultarEntidad = async (entidad) => {
        const { user, eventSelected } = this.state
        let myHeaders = new Headers();
        if(!user?.hash) {
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.token)
        }
        const apiBaseUrl = urlServer + `/api/getEntityWhereEventId?event_id=${user && user.event && user.event[eventSelected].id}&entityName=${entidad}`
        const response = await fetch(apiBaseUrl, {
            method: 'GET',
            headers: myHeaders,
        })
        .then(async response => {
            if(parseInt(response.status) === 200) {
                const data = await response.json()
                return data
            } else {
                console.log(response.status)
            }

        })
        .catch(res => {
            console.log(res)
        })
        return response
    }

    init = async () => {
        this.consultarCalculadora()
        if(!this.state.tabcalculadora && !this.state.tabpresupuestos) {
            const { user, calcsguardadas } = this.state
            await this.setState({
                tabcalculadora: ((user.permisos.find(e => e.permiso && e.permiso.indexOf("save_calculators") !== -1)) || calcsguardadas.length > 0) ? true : false,
                tabpresupuestos: ((user.permisos.find(e => e.permiso && e.permiso.indexOf("save_calculators") !== -1)) || calcsguardadas.length > 0) ? false : true,
            })
        }
        let items = {}

        if(this.props.itemsCalculadora) {
            for (let i = 0; i < this.props.itemsCalculadora.length; i++) {
                const element = this.props.itemsCalculadora[i]
                let key = Object.keys(element)
                if (!items[element[key].ID_Item]) {
                    items[element[key].ID_Item] = []
                }
                let multiplicador = items[element[key].ID_Item].length + 1
                let el = {
                    ...element[key]
                }
                el.label = "$" + pesoLocale.format(parseFloat(element[key].Pvp))
                el.value = multiplicador
                el.precio = parseFloat(element[key].Pvp)
                if(el.precio !== 0) {
                    items[element[key].ID_Item] = [
                        ...items[element[key].ID_Item],
                        el
                    ]
                }
            }
        }
        let itemsState = this.state.items
        itemsState = {
            ...itemsState,
            ...items,
        }
        this.setState({
            items: itemsState,
            itemsNuevo: itemsState,
        })
    }

    async componentDidMount() {
        await this.init();
        await this.consultarPresupuestos();
        this.setState({
            loading: false,
        });
    }

    consultarPresupuestos = async () => {
        const { user, eventSelected } = this.state
        const data = {
            event_id: user && user.event && user.event[eventSelected].id
        }

        const response = await postmanejado("/api/auth/getListDocuments", data, true)
        .then((data) => {
            return data.sort(sortByDate)
        });
        this.setState({
            presupuestos: response
        })
        return await response
    }

    render() {
        const { tabcalculadora, tabpresupuestos, user, calcsguardadas, loading } = this.state
        
        if(!loading) {

            return(
                <>
                    {((user.permisos.find(e => e.permiso && e.permiso.indexOf("save_calculators") !== -1)) || calcsguardadas.length > 0) &&
                    <div className="tabs my-3 mx-auto">
                        <div className="d-flex justify-content-around p-1 f-14">
                            <div className={"tab px-4 px-md-5" + (tabcalculadora? " active" : "")} onClick={() => {
                                this.setState({
                                    tabcalculadora: true,
                                    tabpresupuestos: false,
                                })
                            }}>
                                CALCULADORA
                            </div>
                            <div className={"tab px-4 px-md-5" + (tabpresupuestos? " active" : "")} onClick={() => {
                                this.setState({
                                    tabcalculadora: false,
                                    tabpresupuestos: true,
                                })
                            }}>
                                PROPUESTAS
                            </div>
                        </div>
                    </div>}
                    {((user.permisos.find(e => e.permiso && e.permiso.indexOf("save_calculators") !== -1)) || calcsguardadas.length > 0) && tabcalculadora && this.renderCalculadora()}
                    {tabpresupuestos && this.renderPresupuestos()}
                </>
            )
        } else {
            return <div></div>
        }
    }
}