const { origin, hostname, host } = window.location;

let server = {
    host: host,
};

if(hostname === "localhost" || hostname === "local.sarapuraclub.com") {
    server.host = "local.webappsarapura.com:8000";
} else if(hostname === "abzworks.com") {
    server.host = "sarapura.abzworks.com";
}

let http = origin.indexOf('https') !== -1 ? 'https://' : 'http://';

const url = http + server.host;

module.exports = url;