import React, { Component } from "react";
import { postmanejado } from './fetchmanejado';
export default class Musica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canciones: [],
            invitados: this.props.invitados ? this.props.invitados : [],
            loading: false,
            tooltips: []
        };
    }

    getMusica = async (busqueda) => {
        let data = { query: busqueda };
        const response = await postmanejado("/api/getTrack", data);
        return response;
    };

    buscarSpotify = async (busqueda) => {
        this.setState({ tracks: [], loadeds: 0, loading: true });
        const { tracks } = await this.getMusica(busqueda);
        this.setState({ tracks });
    };

    seleccionarItem = async (item, track, index) => {
        
        let { selected } = this.state;
        if (item.checked) {
            selected = [...selected, { id: track.id, selected: item.checked, index }];
        } else {
            selected = selected.filter(tr => tr.id !== track.id);
        }
        await this.setState({ selected });
    };

    enviarData = async (endpoint, data) => {
        const response = await postmanejado(endpoint, data);
        return response;
    }

    submitCanciones = async (invitado, canciones) => {
        for(let i = 0; i < canciones.length; i++) {
            let cancion = canciones[i];
            const response = await this.enviarData("/insertCancion", {invitado_id: invitado.id, cancion});
            console.log(response);
        }
    };

    getTotalVotos = async () => {
        if(this.props.user && (this.props.eventSelected || (this.props.eventSelected === 0))) {
            const { user, eventSelected } = this.props
            const canciones = await postmanejado("/api/auth/getTotalVotosCancion", {event_id: user.event[eventSelected].id}, true);
            await this.setState({
                canciones
            });
            return canciones;
        }
        return [];
    }
    
    init = async () => {
            
        const canciones = await this.getTotalVotos();

        let nuevasCanciones = false;
        let haceUnaSemana = new Date();
        haceUnaSemana.setDate(haceUnaSemana.getDate() - 7);
        if(canciones?.filter(cancion => {
            return cancion.invitados?.filter(invitado => {
                let fechaCreacion = new Date(invitado.pivot.created_at);
                return fechaCreacion >= haceUnaSemana;
            }).length > 0
        }).length > 0)
        {
            nuevasCanciones = true;
        }
        if(this.props?.setNotif) {
            this.props.setNotif({
                'Musica': nuevasCanciones
            })
        }

        let tooltips = [];
        canciones.sort((a, b) => b.invitados.length - a.invitados.length).forEach((cancion, index) => {
            let tooltip = index + 1;
            if(!tooltips.find(tip => tip === tooltip)) {
                tooltips.push(tooltip);
            }
        })

        for (let i = 0; i < tooltips.length; i++) {
            const id = tooltips[i];

            const respuesta = document.getElementById("cancion-"+id);
            const tooltip = document.getElementById("tooltip-"+id);

            respuesta?.addEventListener("mousemove", (event) => {
                tooltip.style.display = "block";
                tooltip.style.left = "100%";
                tooltip.style.top = "0";
            });
        
            respuesta?.addEventListener("mouseout", () => {
                tooltip.style.display = "none";
            });
        }
        return;
    }
    
    async componentDidMount() {
        this.init();
    }

    render() {
        let { loading, canciones } = this.state;

        return (
        <div>
            <div className={"mt-2 lista-temas " + (loading ? "position-fixed" : "")} style={loading ? {opacity: "0"} : {}}>
                {canciones.length > 0 ? canciones.sort((a, b) => b.invitados.length - a.invitados.length).map((cancion, index) => {
                    let tooltip = index + 1;
                    return (
                        <div key={index} id={"cancion-"+tooltip} className="item-grid d-flex flex-wrap align-items-start iframe-spotify mb-2 card p-3 position-relative">
                            <iframe title={cancion.spotify_id} className="col-12" src={"https://open.spotify.com/embed/track/" + cancion.spotify_id + "?utm_source=generator"} 
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                width="100%" height="80px" frameBorder="0"
                            />
                            <strong className="pt-3">Cantidad de votos: {cancion.invitados.length}</strong> 
                            <span id={"tooltip-"+tooltip} className="tooltip-rta">
                                {cancion.invitados.map((invitado, index) => {
                                    return <p key={index}> {invitado.name}</p>;
                                })}
                            </span>
                        </div>
                    );
                    }):
                    <div>
                        No se encontraron canciones con votos
                    </div>    
                }
            </div>
        </div>
        );
    }
}
