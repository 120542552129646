import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactSVG } from 'react-svg'

import urlServer from './server'

import DiaDelEvento from './diadelevento.js'
import Calculadora from './calculadora.js'
import Musica from './musica.js'
import Trivia from './trivia.js'
import Fotos from './fotos.js'
import ManejarModulos from './manejar_modulos'
import Invitado from './invitado.js'

import editar from './assets/editar.svg'
import guardar from './assets/guardar.svg'
import noche from './assets/noche.svg'
import dia from './assets/dia.svg'
import calendario from './assets/calendario.svg'
import Configuracion from './configuracion.js'
import cruz from './assets/cruz.svg'
import camara from './assets/camara.svg'
import ubicacion from './assets/ubicacion.svg'
import logo_naranja from './assets/logo_naranja.png'
import foto_default from './assets/brandlogo.png'
import sarapura_brand from './assets/sarapura-brand.png'

import { postmanejado } from './fetchmanejado';

// import qrtest from './assets/qrtest.svg'


import { DateTimePicker,
    MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ItemAccordion } from './accordion';
const MySwal = withReactContent(Swal)

const modulosComponents = {
    'Calculadora': Calculadora,
    'Musica': Musica,
    'Trivia': Trivia,
    'Fotos': Fotos
  };  

export default class Landing extends Component {
    constructor(props){
        super(props)
        this.state={
            user: this.props.user ? this.props.user : JSON.parse(localStorage.user),
            date: new Date(),
            locale: "es",
            loading: true,
            eventSelected: this.props.eventSelected || 0,
            ahora: new Date(),
            registroactivo: false,
            tabinvitados: true,
            tabtimeline: false,
            tabmesas: false,
            tabselected:"fiesta",
            editandoTexto: false,
            editandoSubtexto: false,
            diaDelEvento: true,
            calculadora: false,
            configurando: false,
            inicio: true,
            invitados: [],
            fechaCierre: [],
            dateTime: new Date(),
            itemsCalculadora: [],
            notificaciones: {},
            modulos: [],
            data: {
                eventos: [{fechahora: new Date(),}],
            },
            civil: [],
            ceremonia: [],
            temp: {},
            tabs: 0,
            tempfoto: "",
            params: new URLSearchParams(window.location.search),
        }
    }

    setConfiguracion = async (data) => {
        await this.setState({
            ...data
        })
    }

    cambiarData = (array, newData) => {
        if(newData) {
            let { user, eventSelected } = this.state
            let cambio = {
            }
            cambio[array] = newData
            user.event[eventSelected] = {
                ...user.event[eventSelected],
                ...cambio
            }
            this.setState({
                user,
                temp: {}
            })
        }
        return
    }

    calcularProximaHora = () => {
        const now = new Date()
        setTimeout(() =>{
            this.setState({
                ahora: new Date(),
            })
            setInterval(() => {
                this.setState({
                    ahora: new Date(),
                })
            }, 3600000);
        }, (3600000 - now % 3600000))
    }

    subirFoto = async (id) => {
        const foto = this.state.tempfoto
        const file = new File([foto], "FotoEnviada", {type: foto.type} )
        const data = {
            id,
            fileUpload: file
        }
        const response = await postmanejado("/api/uploadFile", data)
        return await response
    }

    editarTabla = async () => {
        const { user, eventSelected }= this.state
        let { pivot, modulos, modulos_contratados, ...event } = user.event[eventSelected]
        let data = {
            id: user.event[eventSelected].id,
            axn: "update",
            fields: JSON.stringify(event),
            entity: "Event"
        }
        let response = await this.enviarData(data)
        return await response
    }

    crearEvento = async (evento, entidad) => {
        let data = {
            axn: "create",
            fields: JSON.stringify(evento),
            entity: entidad
        }
        let response = await this.enviarData(data)
        return await response
    }

    enviarData = async (data) => {
        let response
        await MySwal.fire({
                title: 'Realizando cambios',
                didOpen: async () => {
                    MySwal.showLoading()
                    response = await postmanejado("/api/auth/abmRowFromModel", data, true)
                    MySwal.clickConfirm()
                },
        })
        if(response.response===true) {
            MySwal.fire({
                icon: 'success',
                title: 'Se han guardado los cambios con éxito',
                confirmButtonText: 'Aceptar'
            })
        }
        return await response
    }

    cambiarFoto = async (id) => {
        const response = await MySwal.fire({
            title: 'Subiendo archivo!',
            didOpen: async () => {
                MySwal.showLoading()
                const res = await this.subirFoto(id)
                .then(async result => {
                    await setTimeout(async () => {
                        let { user, eventSelected } = this.state
                        user.event[eventSelected] = {
                            ...user.event[eventSelected],
                            image: result
                        }
                        await this.setState({
                            user
                        })
                        await this.editarTabla()
                        await this.props.consultarUsuario()
                    }, 100)
                    return result
                })
                .catch(err => err)
                return await res
            },
        }).then((result) => {
            return result
        })
        return response
    }

    consultarEventoSP = async () => {
        const { user, eventSelected } = this.state
        const apiBaseUrl = `https://www.sarapuradjs.com.ar/paullibrary/api_rest/apip.php?acc=evento&id=${user && user.event && user.event[eventSelected] && user.event[eventSelected].id}&key=6f2867334bf6fb6fbd9cb8cb030735636ea5eeee`
        const response = await fetch(apiBaseUrl)
        .then(async response => {

            if(parseInt(response.status) === 200) {
                const res = await response.json();
                let data, itemsCalculadora;
                if(res.length > 0) {
                    const evento = res[0]["Evento"]
                    data = {
                        eventos: [
                            {
                                ...evento,
                                evento: evento.tipo_de_evento,
                                tipo: "fiesta",
                                fechahora: evento && evento.fecha && new Date(evento.fecha + " " + (evento.hora_inicio || "")),
                                name: evento && evento.lugar && evento.lugar[0] && evento.lugar[0].nombre
                            }
                        ]
                    };
                    itemsCalculadora = res[1];
                }
                return { data, itemsCalculadora }
            } else {
                console.log(response.status)
            }
        })
        .catch(res => {
            console.log(res)
        })
        return response
    }

    consultarEntidad = async (entidad) => {
        const { user, eventSelected } = this.state
        let myHeaders = new Headers();
        if(!user?.hash) {
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.token)
        }
        const apiBaseUrl = urlServer + `/api/getEntityWhereEventId?event_id=${user?.event && user.event[eventSelected]?.id}&entityName=${entidad}${user?.hash ? `&hash=${user?.hash}` : ''}${user?.hash ? `&invitadoId=${user?.id}` : ''}`
        const response = await fetch(apiBaseUrl, {
            method: 'GET',
            headers: myHeaders,
        })
        .then(async response => {

            if(parseInt(response.status) === 200) {
                const data = await response.json()
                return data
            } else {
                console.log(response.status)
            }

        })
        .catch(res => {
            console.log(res)
        })
        return response
    }

    consultarEventos = async () => {
        const {user} = this.state
        let civil = [];
        let ceremonia = [];
        if(!(user && user.hash)) {
            civil = (await this.consultarEntidad("civils"))
            ceremonia = (await this.consultarEntidad("ceremonias"))
            if(typeof(civil) !== "object") {
                civil = []
            }
            if(typeof(ceremonia) !== "object") {
                ceremonia = []
            }
            civil = civil.map(item => {
                item.fechahora = new Date(item.fechahora)
                return item
            })
            ceremonia = ceremonia.map(item => {
                item.fechahora = new Date(item.fechahora)
                return item
            })
        }
        return {civil, ceremonia}
    }

    AgregarEventos = (civil, ceremonia, user) => {
        const titulo = "Agregar evento"
        const selectF = () => {
            let select = []
            if(civil.length < 1) {
                select = [
                    ...select,
                    "Civil"
                ]
            }
            if(ceremonia.length < 1) {
                select = [
                    ...select,
                    "Ceremonia"
                ]
            }

            // MODAL PARA AGREGAR EVENTO
            
            return(
                <div>
                    <div className="w-100 mb-3 text-start" >

                        {select.length > 0 && 
                            <select defaultValue={select[0]} id="tipoEvento" className="input-modal evento mb-3 form-control" >
                                {select.map((sel, index) => {
                                    return (
                                        <option value={sel} key={index}>
                                            {sel}
                                        </option>)
                                })}
                            </select>
                        }

                        <div className="w-100 mb-3" >
                            <input id="nombre" type="text" className="input-modal evento form-control" placeholder="Nombre"/> 
                        </div>
                        <div className="w-100 mb-3" >
                            <input id="ubicacion" type="text" className="input-modal evento form-control" placeholder="Ubicación" /> 
                        </div>
                        <div className="w-100 mb-3" >
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} >
                                    <DateTimePicker
                                        autoOk
                                        ampm={false}
                                        disablePast
                                        cancelLabel={"Cancelar"}
                                        okLabel={"Aceptar"}
                                        value={this.state.date}
                                        onChange={async (e) => {
                                            await this.setState({
                                                date: e,
                                                dateTime: e.toJSON().slice(0, 19).replace('T', ' '),
                                            })
                                            const nombre = document.getElementById("nombre")
                                            const ubicacion = document.getElementById("ubicacion")
                                            const tipoEvento = document.getElementById("tipoEvento")
                                            const temp = {
                                                nombre: nombre.value,
                                                ubicacion: ubicacion.value,
                                                tipoEvento: tipoEvento.value
                                            }
                                            MySwal.update({
                                                title: titulo
                                            })
                                            let nuevoNombre = document.getElementById("nombre")
                                            let nuevaUbicacion = document.getElementById("ubicacion")
                                            let nuevoTipoEvento = document.getElementById("tipoEvento")
                                            nuevoNombre.value = temp.nombre
                                            nuevaUbicacion.value = temp.ubicacion
                                            if(nuevoTipoEvento) {
                                                nuevoTipoEvento.value = temp.tipoEvento
                                            }
                                        }}
                                        label="Fecha del evento"
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>
            )
        }

        // SWEET ALERT CON ACCION DE AGREGAR EVENTO

        MySwal.fire({
            title: titulo,
            confirmButtonText: "Agregar",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            customClass: {
                "title": "agregar-modal",
                "popup": "agregar-modal",
            },
            html: `
            <div id="agregarCivil" class="w-100">
            </div>
            `,
            didRender: () => {
                ReactDOM.render(React.createElement(selectF, {}), document.getElementById("agregarCivil"));
            },
            preConfirm: async () => {
                MySwal.showLoading()
                const inputNombre = document.getElementById("nombre")
                const inputUbicacion = document.getElementById("ubicacion")
                const inputEvento = document.getElementById("tipoEvento")
                const { user, eventSelected, dateTime } = this.state 
                let nuevoEvento = {
                    name: inputNombre.value,
                    event_id: user.event[eventSelected].id,
                    estado_id: 2,
                    ubicacion: inputUbicacion.value,
                    fechahora: dateTime
                }
                nuevoEvento.fechahora = new Date(nuevoEvento.fechahora)
                let d = new Date(nuevoEvento.fechahora.getTime() - (nuevoEvento.fechahora.getTimezoneOffset() * 60 * 1000));

                let Y = d.getFullYear();
                let m = (d.getMonth() + 1).toLocaleString('es', {
                    minimumIntegerDigits: 2});
                let D = d.getDate().toLocaleString('es', {
                    minimumIntegerDigits: 2});
                let H = d.getHours().toLocaleString('es', {
                    minimumIntegerDigits: 2});
                let M = d.getMinutes().toLocaleString('es', {
                    minimumIntegerDigits: 2});
                let S = d.getSeconds().toLocaleString('es', {
                    minimumIntegerDigits: 2});
                
                let stringFecha = Y + '-' +  (m) + '-' + (D) + 'T' + (H) + ':' + (M) + ':' + (S); 
                nuevoEvento.fechahora = stringFecha

                await this.crearEvento(nuevoEvento, inputEvento.value)
                .then(async (res) => {
                    if(res.insertedIDSys) {
                        const { civil, ceremonia } = await this.consultarEventos()
                        let tabs = 1;
                        if (civil.length > 0) {
                            tabs+=1
                        }
                        if (ceremonia.length > 0) {
                            tabs+=1
                        }
                        this.setState({
                            civil,
                            ceremonia,
                            tabs
                        })
                    }
                })
            }
        })
    }

    componentDidMount = async () => {
        await this.props.setLoading(true)
        const { user, eventSelected } = this.props
        if((!(user && user.hash)) && user.event[eventSelected]) {
            await this.props.setBackground(user.event[eventSelected].custom_background)
        }
        await this.init()
        await this.props.setLoading(false)
        if(this.props.error && this.props.error.error) {
            MySwal.fire({
                icon: 'error',
                title: 'No tiene acceso al evento: ' + this.props.error.evento,
            })
        }
    }

    init = async () => {
        //Agrega ID a la url
        const { user, eventSelected } = this.props
        if((!(user?.hash))) {
            const id = user?.event?.find((ev, index) => index === eventSelected)?.id
            if (id) {
                let url = new URL(window.location.href);
                let search_params = url.searchParams;
                search_params.set('evento', id);
                url.search = search_params.toString();
                let new_url = url.toString();
                window.history.pushState({}, '', new_url);
            }
                await this.getInvitados();
        }
        await this.calcularProximaHora()
        //Funciones para iniciar
        const { civil, ceremonia } = await this.consultarEventos()
        const { data, itemsCalculadora } = await this.consultarEventoSP()
        
        let tabs = 0
        if (data.eventos.length > 0) {
            tabs+=1
        }
        if (civil.length > 0) {
            tabs+=1
        }
        if (ceremonia.length > 0) {
            tabs+=1
        }
        this.setState({
            civil, 
            ceremonia,
            data,
            itemsCalculadora: itemsCalculadora.Items,
            tabs,
            evento: data.eventos[0],
            loading: false
        });
    }

    

    getInvitados = async () => {
        const { user, eventSelected } = this.props;
        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.token);
        const apiBaseUrl = urlServer + `/api/auth/getInvitadosLista?event_id=${user?.event && user.event[eventSelected]?.id}`
        await fetch(apiBaseUrl, {
            method: 'GET',
            headers: myHeaders,
        })
        .then(async response => {
            if(parseInt(response.status) === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                MySwal.fire({
                    icon: 'error',
                    title: "Su sesión ha expirado.",
                    showConfirmButton: true,
                })
                .then(res => {
                    window.location.reload();
                })
                return
            } else if(parseInt(response.status) === 200) {
                const data = await response.json()
                const invitados = data.map(invitado => {
                    return invitado
                })
                let parents = invitados.filter(invitado => !invitado.parent_id);
                let children = invitados.filter(invitado => invitado.parent_id);
                let invitadosFinal = [];
                parents.forEach(el => {
                    invitadosFinal = [
                        ...invitadosFinal,
                        el,
                        ...children.filter(child => child.parent_id === el.id)
                    ]
                });

                if(typeof(data) === "object") {
                    this.setState({
                        invitados: invitadosFinal,
                        // calculadora: JSON.parse(data[0].text)
                    })
                }
            }
        })
        .catch(res => {
            console.log(res)
        })
    }

    setNotif = (notificacion) => {
        this.setState({
            notificaciones: {
                ...this.state.notificaciones,
                ...notificacion
            }
        })
    }

    render() {
        const { tabselected, calculadora, diaDelEvento,
                data, ahora, editandoTexto, invitados,
                editandoSubtexto, 
                civil, ceremonia, loading, tabs, itemsCalculadora } = this.state
        let { evento } = this.state

        const { user, eventSelected } = this.props

        let modulos = user?.event[eventSelected]?.modulos || []
        modulos = modulos.map((modulo,index) => modulos.findIndex((mod) => mod.nombre === modulo.nombre) === index && ({
                nombre: modulo.nombre,
                titulo: modulo.titulo,
                Componente: modulosComponents[modulo.nombre]
        })).filter(modulo => modulo)

        switch (tabselected) {
            case "fiesta":
                evento = data.eventos[0]
                break;
            case "civil":
                evento = civil[0]
                break;
            case "ceremonia":
                evento = ceremonia[0]
                break;
            default:
                evento = data.eventos[0]
        }

        return (
            <>
            <div id='landing' className={"container pt-4 scroll" + ((user && user.event &&  user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo") + (loading? " scrollnone" : "")} style={{paddingBottom: !(user && user.hash) ? null : "7rem"}}>

                {user && user.event && user.event[eventSelected] ?
                    <>
                    <div className={"position-relative col-12 d-flex py-4 mb-4 justify-content-between " + (user.hash ? "col-md-8 mx-auto" : "")} style={{minHeight: "60px"}}>
                        {/* Logo Sarapura */}
                        <div>
                            <img src={sarapura_brand} className="brand-logo-home" alt="Logo de marca" style={{position: "absolute", left: "0"}} />
                        </div>
                        {/* // Logo Sarapura // */}

                        {/* Logo Sarapura */}
                        <div>
                            <img src={logo_naranja} className="brand-logo-home mx-auto" alt="Logo de marca" style={{position: "absolute", left: "0", right: "0"}} />
                        </div>
                        {/* // Logo Sarapura // */}
                        
                        {/* CONFIGURACIÓN */}
                        {(!user.hash) &&
                        <Configuracion 
                            user={user}
                            eventSelected={eventSelected}
                            diaDelEvento={diaDelEvento}
                            calculadora={calculadora}
                            setState={this.setConfiguracion}
                            setEvent={this.props.setEvent}
                            iniciar={this.componentDidMount}
                            setBackground={this.props.setBackground}
                            consultarUsuario={this.props.consultarUsuario}
                            loggingOut={this.props.loggingOut}
                        />
                        }
                        {/* // CONFIGURACIÓN */}
                    </div>

                        <div className={"row "}>
                            <div className={"landing-evento d-flex flex-wrap " + (user.hash ? "col-md-8 mx-auto" : "")}>
                                <div className="position-relative col-12 col-md-5">
                                    {/* IMAGEN DEL EVENTO */}
                                    <div className="position-relative" style={{margin: "auto"}}>
                                        {(this.state.user.event[eventSelected].image ?
                                            <div className="div-foto">
                                                <div className="borde-degrade">
                                                    <img className="foto-evento" src={`${urlServer}/storage/uploads/${user.event[eventSelected].image}`} alt="Imagen del evento" />
                                                </div>
                                            </div>
                                            :<div className="div-foto" style={{backgroundImage: "linear-gradient(30deg, #88039c 0%, rgb(75, 75, 245) 85%)"}}>
                                                <div className="borde-degrade">
                                                    <img className="foto-evento" src={foto_default} alt="Imagen del evento" />
                                                </div>
                                            </div>)
                                        }

                                        <input id="filefoto" type="file" className="d-none" onChange={async (e) => {

                                            var file = e.target.files[0];
                                            if(file) {
                                                var url2 = URL.createObjectURL(file)
                                                await this.setState({
                                                    urlfoto: url2,
                                                    tempfoto: e.target.files[0]
                                                })
                                                await this.cambiarFoto(user.event[eventSelected].id)
                                                .then(res => console.log(res))
                                            }
                                        }} />

                                        {(user.permisos && 
                                        user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_info") !== -1)) &&
                                        <div className={"svg img-landing back" + user.event[eventSelected].custom_color} onClick={() => {
                                                const input = document.getElementById("filefoto")
                                                input.click()
                                            }}>
                                                <img 
                                                    src={camara}
                                                    alt="Agregar foto"
                                                />
                                            </div>
                                            }
                                    </div>
                                    {/* // IMAGEN DEL EVENTO // */}
                                    
                                </div>

                                {/* TITULO Y SUBTITULO */}
                                <div className="col-12 col-md-7 titulo">
                                    <div className="d-flex justify-content-center justify-content-md-start align-items-center position-relative mt-3" style={{minHeight: "60px"}}>
                                        {!editandoTexto ? 
                                        <div className="titulo">
                                            <h2>
                                                {user.event[eventSelected].title}
                                            </h2>
                                        </div>:
                                        <input className="col-6 text-center p-2 form-control" type="text" value={this.state.temp.texto} onChange={(e) => {
                                            let temp = {
                                                texto: e.target.value
                                            }
                                            this.setState({
                                                temp
                                            })
                                        }} />
                                        }
                                        {(user.permisos && 
                                        user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_info") !== -1)) && (!editandoTexto ? 
                                        <div className={"position-absolute svg axn back" + user.event[eventSelected].custom_color} style={{right: "0", cursor: "pointer", borderRadius: "50%", padding: "5px"}} onClick={() => {
                                            this.setState({
                                                editandoTexto: true
                                            })
                                        }}>
                                            <ReactSVG src={editar} style={{width: "13px", height: "13px"}} />
                                        </div> :
                                        <div className="d-flex position-absolute" style={{right: "0"}}>
                                            <div className={"svg axn back" + user.event[eventSelected].custom_color} style={{ margin: "auto auto auto 5px", cursor: "pointer", borderRadius: "50%", padding: "6px"}} onClick={async () => {
                                                const { texto } = this.state.temp
                                                await this.cambiarData("title", texto)
                                                await this.editarTabla()
                                                await this.props.consultarUsuario()
                                                this.setState({
                                                    editandoTexto: false
                                                })
                                            }}>
                                                <ReactSVG src={guardar} style={{width: "17px", height: "17px", marginTop: "-1px"}} />
                                            </div>
                                            <div className={"svg axn back" + user.event[eventSelected].custom_color} style={{ margin: "auto auto auto 5px", cursor: "pointer", borderRadius: "50%", padding: "9px 6px"}} onClick={() => {
                                                this.setState({
                                                    editandoTexto: false,
                                                    temp: {}
                                                })
                                            }}>
                                                <ReactSVG 
                                                    src={cruz}
                                                    style={{width: "17px", height: "17px", marginTop: "-6px"}} 
                                                />
                                            </div>
                                        </div>)
                                        }
                                    </div>
                                    <div className="d-flex justify-content-center justify-content-md-start align-items-center position-relative mt-3 mb-4" style={{minHeight: "60px"}}>
                                        {!editandoSubtexto ? 
                                        <div className={"subtitulo " + user.event[eventSelected].custom_color}>
                                            <h5>
                                                {user.event[eventSelected].subtext}
                                            </h5>
                                        </div>:
                                        <div className="col-12 col-md-6">
                                            <input type="text" className="text-center p-2 form-control" value={this.state.temp.subtexto} onChange={(e) => {
                                                this.setState({
                                                    temp: {
                                                        subtexto: e.target.value
                                                    }
                                                })
                                            }} />
                                        </div>
                                        }
                                        {(user.permisos && user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_info") !== -1)) && (!editandoSubtexto ? 
                                        <div className={"position-absolute svg axn back" + user.event[eventSelected].custom_color} style={{right: "0", cursor: "pointer", borderRadius: "50%", padding: "5px"}} onClick={() => {
                                            this.setState({
                                                editandoSubtexto: true
                                            })
                                        }}>
                                        <ReactSVG 
                                            src={editar}
                                            style={{width: "13px", height: "13px"}} />
                                        </div> :
                                        <div className="d-flex position-absolute" style={{right: "0"}}>
                                        <div className={"svg axn back" + user.event[eventSelected].custom_color} style={{ margin: "auto", cursor: "pointer", borderRadius: "50%", padding: "6px"}} onClick={async () => {
                                                const { subtexto } = this.state.temp
                                                await this.cambiarData("subtext", subtexto)
                                                await this.editarTabla()
                                                await this.props.consultarUsuario()
                                                this.setState({
                                                    editandoSubtexto: false
                                                })
                                            }}>
                                                <ReactSVG 
                                                    src={guardar}
                                                    style={{width: "17px", height: "17px", marginTop: "-1px"}} 
                                                />
                                            </div>
                                            <div className={"svg axn back" + user.event[eventSelected].custom_color} style={{ margin: "auto auto auto 5px", cursor: "pointer", borderRadius: "50%", padding: "9px 6px"}} onClick={() => {
                                                this.setState({
                                                    editandoSubtexto: false,
                                                    temp: {}
                                                })
                                            }}>
                                                <ReactSVG 
                                                    src={cruz}
                                                    style={{width: "17px", height: "17px", marginTop: "-6px"}} 
                                                />
                                            </div>
                                        </div>)
                                        }
                                    </div>

                                    {/* // TITULO Y SUBTITULO */}

                                    {evento &&

                                    <div className="d-flex justify-content-center justify-content-md-start col-md-6 col-12 mx-auto mx-md-0" style={{minWidth: 'fit-content'}}>
                                        {/* COUNTDOWN */}
                                        <div className="d-flex cuenta-regresiva w-100 px-2">
                                            {
                                            (evento.fechahora - ahora) < 0 ?
                                            <h6 className="text-center mx-auto my-0">
                                                Este evento ya ha finalizado
                                            </h6>
                                            :    
                                            <React.Fragment>
                                                <div className="d-flex align-items-end">
                                                    <h1 className="my-0 mx-1">
                                                        {Math.ceil((evento.fechahora - ahora) / (1000 * 3600 * 24))}
                                                    </h1>
                                                    <h3 className={"fw-300 my-0 mx-1 " + user.event[eventSelected].custom_color }>
                                                        Días
                                                    </h3>
                                                </div>
                                                <h1 className="fw-400 m-0 fucsia">
                                                    :
                                                </h1>
                                                <div className="d-flex align-items-end">
                                                    <h1 className="my-0 mx-1">
                                                        {Math.ceil(((evento.fechahora - ahora) % (1000 * 3600 * 24))/(1000 * 3600))}
                                                    </h1>
                                                    <h3 className={"fw-300 my-0 mx-1 " + user.event[eventSelected].custom_color }>
                                                        Horas
                                                    </h3>
                                                </div>
                                            </React.Fragment>}
                                        </div>
                                        {/* // COUNTDOWN // */}
                                    </div>

                                    }
                                </div>
                            </div>


                        </div>

                        <div className={"mt-5 row-accordeon " + (user.hash ? "d-flex col-md-8 mx-auto flex-column" : "")}>
                        {/* INFORMACION DEL EVENTO */}
                        {evento && 
                        <div className="div1">
                            <div className="card p-3">
                                <div className={"text-start " + user.event[eventSelected].custom_color }>
                                    <h6 className="sub">
                                        INFORMACIONES GENERALES
                                    </h6>
                                </div>
                                <div>
                                    {tabs > 1 && <div className="w-100 tabs mt-3">

                                        {/* TABS */}
                                        <div className="d-flex justify-content-around p-1">
                                            {data.eventos.find(evento => evento.tipo === "fiesta") &&
                                                <div className={"tab" + (tabselected === "fiesta"? " active" : "")} onClick={() => {
                                                    this.setState({
                                                        tabselected: "fiesta",
                                                    })
                                                }}>
                                                    CASAMIENTO
                                                </div>
                                            }

                                            {this.state.civil.length > 0 &&
                                                <div className={"tab" + (tabselected === "civil" ? " active" : "")} onClick={() => {
                                                this.setState({
                                                    tabselected: "civil",
                                                })
                                            }}>
                                                CIVIL
                                            </div>}

                                            {this.state.ceremonia.length > 0 &&
                                                <div className={"tab" + (tabselected === "ceremonia"? " active" : "")} onClick={() => {
                                                this.setState({
                                                    tabselected: "ceremonia",
                                                })
                                            }}>
                                                CEREMONIA
                                            </div>}
                                        </div>
                                        {/* // TABS // */}


                                    </div>}
                                </div>

                                <div className="text-start"> 

                                    <div className="mt-3 d-flex align-items-start svg">
                                        <div style={{width: "35px", height: "35px"}}>
                                            <ReactSVG src={calendario} style={{width: "22px", height:"22px", marginTop: "3px"}}  className={user.event[eventSelected].custom_color}  />
                                        </div>
                                        
                                        <div className="ml-3">
                                            {/* NOMBRE DEL EVENTO */}
                                            <div>
                                                <h4>
                                                    Evento {evento.evento}
                                                </h4>
                                            </div>

                                            {/* FECHA DEL EVENTO */}
                                            {evento.fechahora && 
                                            <div>
                                                <h6 className="sub">
                                                    {evento.fechahora.getDate()} de {evento.fechahora.toLocaleString('es-ES', { month: 'long' })} de {evento.fechahora.getFullYear()}
                                                </h6>
                                            </div>
                                            }

                                        </div>

                                    </div>
                                    
                                    {evento.fechahora &&
                                    <>
                                    <hr />
                                    <div className="mt-3 d-flex f-12 align-items-center svg">
                                            {(evento.fechahora.getHours() >= 6 && evento.fechahora.getHours() < 20) && 
                                            <ReactSVG src={dia} style={{width: "22px", height:"22px", marginRight: "10px"}} className={user.event[eventSelected].custom_color}  />
                                            }
                                            {((evento.fechahora.getHours() >= 20 || evento.fechahora.getHours() < 6)) &&
                                            <ReactSVG src={noche} style={{width: "22px", height:"22px", marginRight: "10px"}} className={user.event[eventSelected].custom_color}  />
                                            }
                                        <div>
                                            <h6 className="sub m-0" style={{lineHeight: "1"}}>
                                                {(evento.fechahora.getHours() >= 6 && evento.fechahora.getHours() < 12) && "Día" 
                                                }
                                                {(evento.fechahora.getHours() >= 12 && evento.fechahora.getHours() < 20) && "Tarde" 
                                                }
                                                {((evento.fechahora.getHours() >= 20 || evento.fechahora.getHours() < 6)) && "Noche" 
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                    </>
                                    }
                                    {evento.fechahora && (evento.name || evento.ubicacion) &&
                                    <hr />
                                    }
                                    
                                    {/* LUGAR Y DIRECCION */}
                                    {(evento.name || evento.ubicacion) &&
                                    <div className="mt-3 d-flex f-12 align-items-center svg">
                                        <ReactSVG src={ubicacion} style={{width: "19px", height:"30px", marginRight: "10px"}}  className={user.event[eventSelected].custom_color}  />
                                        <div className="">
                                            <div>
                                                <h6 className="sub">
                                                    {evento.name}
                                                </h6>
                                            </div>
                                            {evento.ubicacion && 
                                            <a target="_blank" rel="noreferrer" href={"https://maps.google.com?q=" + evento.ubicacion} >
                                                <div>
                                                    <h6 className="sub m-0">
                                                        {evento.ubicacion}
                                                    </h6>
                                                </div>
                                            </a>
                                            }
                                        </div>
                                    </div>
                                    }
                                    {/* // LUGAR Y DIRECCION // */}

                                </div>

                                {/* BOTON AGREGAR QUE DISPARA MODAL PARA AGREGAR EVENTO */}
                                {!(user.hash) && (user.permisos && 
                                    user.permisos.find(e => e.permiso && ((e.permiso.indexOf("add_civil") !== -1) || (e.permiso.indexOf("add_ceremonia") !== -1)))) && 
                                    <div className="d-flex justify-content-end">
                                        {(civil.length === 0 || ceremonia.length === 0) && 
                                        <div className={"agregar-evento mt-3"} onClick={() => {
                                            this.AgregarEventos(civil, ceremonia, user)
                                        }}>
                                            Agregar
                                        </div>}
                                    </div>}
                                {/* // BOTON AGREGAR QUE DISPARA MODAL PARA AGREGAR EVENTO // */}


                                {/* {user && user.hash &&
                                    <div className="mt-4">
                                        <div className="my-3">
                                            Entrada al Evento
                                        </div>
                                        <div className="my-4">
                                        <ReactSVG src={qrtest} />
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                        }
                                {!loading &&
                                !(user.hash) &&
                                <ItemAccordion
                                    titulo={"MI EVENTO"}
                                    className="div2"
                                    show={true}
                                    index={1}
                                    user={user}
                                    eventSelected={eventSelected}
                                    body={
                                        <DiaDelEvento 
                                            user={user} 
                                            eventSelected={eventSelected} 
                                            eventoSP={evento}
                                            invitados={invitados}
                                            getInvitados={this.getInvitados}
                                        />
                                    }
                                />
                                }

                                {/* // CALCULADORA // */}
                                {!(user.hash) &&
                                <div className="div3 accordion accordion-flush" id="accordionFlushExample">
                                    {modulos.map((modulo, index) => {
                                        let props = { user, event: evento, eventSelected, setNotif: this.setNotif };
                                        if (modulo.nombre === 'Calculadora') {
                                            props.calculadora = calculadora;
                                            props.itemsCalculadora = itemsCalculadora;
                                        }
                                        if (modulo.nombre === 'Fotos' ||
                                            modulo.nombre === 'Musica' ||
                                            modulo.nombre === 'Trivia') {
                                            props.invitados = this.state.invitados;
                                        }
                                        return !loading && <ItemAccordion
                                            key={index}
                                            titulo={modulo.titulo.toUpperCase()}
                                            contenidoNuevo={this.state.notificaciones[modulo.nombre]}
                                            index={index+2}
                                            user={user}
                                            eventSelected={eventSelected}
                                            body={
                                                <modulo.Componente key={index} {...props} />
                                            }
                                        />;
                                    })
                                    }
                                    {!loading && (user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_modulos") !== -1)) &&
                                    <ItemAccordion
                                        titulo={"HABILITAR SERVICIOS ADICIONALES"}
                                        index={10}
                                        user={user}
                                        eventSelected={eventSelected}
                                        body={
                                            <ManejarModulos
                                                user={this.props.user}
                                                eventSelected={eventSelected}
                                                consultarUsuario={this.props.consultarUsuario}
                                            />
                                        }
                                    />
                                    }
                                    {/* {!loading && 
                                    <ItemAccordion
                                        titulo={"MUSICA"}
                                        index={3}
                                        user={user}
                                        eventSelected={eventSelected}
                                        body={
                                            <Musica
                                                user={user}
                                                eventSelected={eventSelected}
                                            />
                                        }
                                    />
                                    }
                                    {!loading && 
                                    <ItemAccordion
                                        titulo={"FOTOS"}
                                        index={5}
                                        user={user}
                                        eventSelected={eventSelected}
                                        body={
                                            <Fotos
                                                user={user}
                                                eventSelected={eventSelected}
                                                invitados={this.state.invitados}
                                            />
                                        }
                                    />
                                    } */}
                                </div>
                                }
                            </div>
                        
                        {((user.hash) || (user.parent_id)) &&
                            <Invitado user={user} invitados={this.props.invitados} eventSelected={this.state.eventSelected} consultarInvitado={this.props.consultarInvitado} fechaCierre={this.state.fechaCierre} />
                        }
                    </>:

                    <div className="text-white pt-4 position-relative">
                        No tiene ningún evento asociado
                        {(!user.hash) &&
                        <Configuracion 
                            user={user}
                            eventSelected={eventSelected}
                            diaDelEvento={diaDelEvento}
                            calculadora={calculadora}
                            setState={this.setConfiguracion}
                            setEvent={this.props.setEvent}
                            iniciar={this.componentDidMount}
                            setBackground={this.props.setBackground}
                            consultarUsuario={this.props.consultarUsuario}
                            loggingOut={this.props.loggingOut}
                        />
                        }
                    </div>
                    }
                </div></>
        );
    }
}