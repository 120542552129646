import React, { Component } from "react";
import { postmanejado } from './fetchmanejado';
import AgregarPregunta from './agregarPregunta';
import EditarPregunta from './editarPregunta';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
      
// Función auxiliar para obtener el total de votos de una pregunta
const getTotalVotos = (pregunta) => {
  let total = 0;
  pregunta.respuestas.forEach((respuesta) => {
    total += respuesta.scores.length;
  });
  return total || 1;
};
export default class Trivia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preguntas: [],
            tooltips: [],
        };
    }

    enviarData = async (api, data) => {
        const response = await postmanejado(api, data, true);
        return response;
    }

    guardarRespuesta = async (interval, esUltimaPregunta, pregunta_id, respuesta_id, tiempo) => {
        this.finalizarConteo(interval, esUltimaPregunta)
        await this.enviarRespuesta(pregunta_id, respuesta_id, tiempo);
        this.iniciarConteo(esUltimaPregunta);
    }

    getPreguntas = async (event_id) => {
        const response = await postmanejado("/api/getPreguntas", { event_id });
        return response;
    }

    deletePregunta = async (pregunta_id) => {
        const response = await this.enviarData("/api/auth/deletePregunta", { pregunta_id });
        this.init();
        return response;
    }

    eliminarPregunta = async (pregunta_id) => {
        MySwal.fire({
            title: '¿Estás seguro de eliminar esta pregunta?',
            text: "Se eliminará esta pregunta",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.deletePregunta(pregunta_id)
                .then((res) => {
                    console.log(res);
                    MySwal.fire(
                        'Eliminada!',
                        'La pregunta se ha eliminado',
                        'success'
                    )
                });
            }
        })
    }

    init = async () => {
        if(this.props.user && (this.props.eventSelected || (this.props.eventSelected === 0))) {
            const { user, eventSelected } = this.props
            const { preguntas, respuestas, invitados } = await this.getPreguntas(user.event[eventSelected].id);
            await this.setState({
                preguntas, 
                respuestas, 
                invitados
            })
            let tooltips = [];
            let nuevasRespuestas = false;
            let haceUnaSemana = new Date();
            haceUnaSemana.setDate(haceUnaSemana.getDate() - 7);
            preguntas.forEach((preg, index) => {
                preg.respuestas.forEach((respuesta, index) => {
                    const scores = respuesta.scores.filter(score => {
                        let fechaCreacion = new Date(score.created_at);
                        return fechaCreacion >= haceUnaSemana;
                    })
                    if(scores.length > 0) {
                        nuevasRespuestas = true;
                    }
                    let tooltip = respuesta.id+"-"+index;
                    if(!tooltips.find(tip => tip === tooltip) && respuesta.scores.length) {
                        tooltips.push(tooltip);
                    }
                })
            })
            if(this.props?.setNotif) {
                this.props.setNotif({
                    'Trivia': nuevasRespuestas
                })
            }
    
            for (let i = 0; i < tooltips.length; i++) {
                const id = tooltips[i];
                
                const respuesta = document.getElementById("respuesta-"+id);
                const tooltip = document.getElementById("tooltip-"+id);
            
                respuesta.addEventListener("mousemove", (event) => {
                    tooltip.style.display = "block";
                    tooltip.style.left = "100%";
                    tooltip.style.top = "0";
                });
            
                respuesta.addEventListener("mouseout", () => {
                    tooltip.style.display = "none";
                });
            }
        }


    }
    
    Encuesta = (preguntas) => {
        return (
          <div className="container">
            {preguntas.map((pregunta, index) => (
              <div key={index} className="mb-4">
                <h4>{pregunta.pregunta}</h4>
                {pregunta.respuestas.map((respuesta, index) => (
                  <div key={index} className="mb-2">
                    <span>{respuesta.respuesta}</span>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: `${(respuesta.scores.length / getTotalVotos(pregunta)) * 100}%` }}
                      >
                        {((respuesta.scores.length / getTotalVotos(pregunta)) * 100).toFixed(2)}%
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      };


    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.eventSelected !== null && this.props.eventSelected !== undefined && this.props.user.event && this.props.user.event[this.props.eventSelected] &&
        (this.props.user !== prevProps.user || this.props.eventSelected !== prevProps.eventSelected)) {
            this.init();
        }
    }

    componentDidMount() {
        this.init();
    }

    render() {
        let { preguntas } = this.state;
        let { user, eventSelected, invitados } = this.props;

        return (
        <div>

                <>
                    <div className="mt-2 lista-temas d-flex flex-column text-start position-relative">
                        {preguntas && preguntas.map((preg, index) => 
                            <div key={index} className="row flex-wrap justify-content-center mb-2 card p-4 py-5">
                                <h4 className="mb-4">{preg.pregunta}</h4>
                                <div className="col-12 col-md">
                                    <div key={index} className="mb-4">
                                    {preg.respuestas.map((respuesta, index) => {
                                        let tooltip = respuesta.id+"-"+index;
                                        return (<div id={"respuesta-"+tooltip} key={index} className="mb-2 position-relative">
                                            <div className="d-flex justify-content-between">
                                                <span>{respuesta.respuesta}</span>
                                                <span style={{fontSize: "0.85rem"}}>
                                                    {((respuesta.scores.length / getTotalVotos(preg)) * 100).toFixed(2)}%
                                                </span>
                                            </div>
                                            <span id={"tooltip-"+tooltip} className="tooltip-rta">
                                                {respuesta.scores.map((score, index) => {
                                                    let invitado = invitados.find(inv => inv.id === score.invitado_id);
                                                    return <p key={index}> {invitado.name}</p>;
                                                })}
                                            </span>
                                            <div className={"progress " + ((user && user.event && user.event[eventSelected].custom_color) || "settings-amarillo")}>
                                                <div
                                                className="progress-bar"
                                                style={{ width: `${(respuesta.scores.length / getTotalVotos(preg)) * 100}%` }}
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    )})}
                                    </div>
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                    <div className="btn btn-primary" onClick={() => EditarPregunta(user, eventSelected, this.init, preg)}>
                                        Editar
                                    </div>
                                    <div className="btn btn-danger ms-3" onClick={() => this.eliminarPregunta(preg.id)}>
                                        Eliminar
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="btn btn-primary" onClick={() => AgregarPregunta(user, eventSelected, this.init)}>
                        Crear nueva pregunta
                    </div>
                </>
        </div>
        );
    }
}
