import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import moment from 'moment'

import whatsapp from './assets/whatsapp.png'
import reloj from './assets/reloj.svg'
import editar from './assets/editar.svg'
import guardar from './assets/guardar.svg'
import borrar from './assets/borrar.svg'
import AgregarItemTimeline from './agregaritem'

import { postmanejado } from './fetchmanejado';
    
import ReactDOM from 'react-dom';
import * as XLSX from 'xlsx'

import urlServer from './server'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Swal from 'sweetalert2'

export default class DiaDelEvento extends Component {
    constructor(props) {
        super(props);
        this.state={
            user: this.props.user ? this.props.user : JSON.parse(localStorage.user),
            busqueda: "",
            eventSelected: this.props.eventSelected ? this.props.eventSelected : 0,
            num:0,
            timeline: [],
            ahora: new Date(),
            invitados: [],
            registroactivo: false,
            tabinvitados: true,
            tabtimeline: false,
            tabmesas: false,
            tabselected:"fiesta",
            editandoTexto: false,
            editandoSubtexto: false,
            editandoHora: [],
            newhoraInicio: new Date(),
            newhoraFin: new Date(),
            comidas: [],
            precios: {
            },
            calculadora: {
            },
            nuevoTimeline: {
                titulo: "",
                horaInicio: "",
                horaFin:""
            },
            timelinesEditando: [],
            data: {},
            temp: {}
        }
    }
    actualizarFila = async (invitado, entidad) => {
        
        let data = {
            id: invitado.id,
            axn: "update",
            fields: JSON.stringify(invitado),
            entity: entidad
        }
        
        const response = await postmanejado('/api/auth/abmRowFromModel', data, true)
        return await response
    }

    agregarFila = async (invitado, entidad) => {

        let data = {
            id: null,
            axn: "create",
            fields: JSON.stringify(invitado),
            entity: entidad
        }

        const response = await postmanejado('/api/auth/abmRowFromModel', data, true)
        return await response
    }

    eliminar = async (id, entidad) => {
        let data = {
            id: id,
            axn: "delete",
            entity: entidad
        }
        const response = await postmanejado('/api/auth/abmRowFromModel', data, true)
        return await response
    }

    ImportarInvitadoXls = async (invitado) => {
        const response = await postmanejado('/api/auth/importInvitado', invitado, true)
        return await response
    }

    renderComida = (id) => {
        let abreviacion = (this.state.comidas.find(comida => comida.id === id) && this.state.comidas.find(comida => comida.id === id).abreviacion) || 'SR'
        return abreviacion && abreviacion.toUpperCase()
    }

    ordenarPorOrder = (a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        // a must be equal to b
        return 0;
    }

    crearDnd = async (nuevoTimeline) => {
        const { user, eventSelected, timeline } = this.state
        const lastItem = timeline[timeline.length-1]
        let maxindex = (lastItem && lastItem.order) || 0
        const timming = {
            title: nuevoTimeline.name,
            event_id: user.event[eventSelected].id,
            estado_id: 2,
            duracion: nuevoTimeline.time,
            order: maxindex+1,
        }
        await this.agregarFila(timming, "Timeline")
    }

    mostrarHora = (timming, index) => {
        const {timeline} = this.state
        const evento = this.props.eventoSP ? this.props.eventoSP : []
        let hora_inicio = 0
        for (let i = 0; i < timming.order; i++) {
            if(timeline.find(time => time.order === i)) {
                hora_inicio += timeline.find(time => time.order === i).duracion
            }
        }
        return moment((evento && evento.hora_inicio) || "00:00", "hh:mm").add(hora_inicio, "minutes").format("HH:mm")
    }

    mostrarDuracion = (timming) => {
        if(timming.duracion) {
            return timming.duracion + " minutos"
        } else if(timming.hour_start) {
            let hora_inicio = parseInt(timming.hour_start.slice(0,2)) * 60 + parseInt(timming.hour_start.slice(3,5))
            let hora_fin = parseInt(timming.hour_end.slice(0,2)) * 60 + parseInt(timming.hour_end.slice(3,5))
            return (hora_fin - hora_inicio) + " minutos"
        }
    }

    getComidas = async () => {
        const comidas = await fetch(`${urlServer}/api/getComidas`)
        this.setState({
            comidas: await comidas.json()
        })
        return await comidas
    }
    
    getTimeline = async () => {
        const { user, eventSelected } = this.state
        let myHeaders = new Headers();
        if(!user?.hash) {
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.token)
        }
        const apiBaseUrl = urlServer + `/api/getEntityWhereEventId?event_id=${user && user.event && user.event[eventSelected].id}&entityName=${"timelines"}`
        const response = await fetch(apiBaseUrl, {
            method: 'GET',
            headers: myHeaders,
        })
        .then(async response => {
            if(parseInt(response.status) === 200) {
                const data = await response.json()
                if(data.length > 0) {
                    data.sort(this.ordenarPorOrder)
                }
                return data
            } else {
                console.log(response.status)
            }

        })
        .catch(res => {
            console.log(res)
        })
        if(response && (typeof(response) === "object")) {
            this.setState({
                timeline: response
            })
        }
        return
    }

    renderReferencias = () => {
        return (
            <div className="d-flex flex-wrap mt-3 referencias justify-content-between">
                <div className="text-start card mb-3 mb-sm-0 p-3 col-12 col-sm estados">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="punto-presencia-referencia verde mx-1">
                                            
                        </div>
                        <div>
                            CONFIRMADO
                        </div>
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="punto-presencia-referencia amarillo mx-1">
                        </div>
                        <div>
                            PENDIENTE
                        </div>
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="punto-presencia-referencia rojo mx-1">
                        </div>
                        <div>
                            NO VA
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-8 ps-sm-2">
                    <div className="comidas text-start card p-3">

                        <div className="d-flex align-items-center">
                            <div style={{backgroundColor: "#2B2B2B", color: "white", padding: "2px 5px", marginRight: "5px"}}>
                                SR
                            </div>
                            Sin restricción
                        </div>
                        {this.state.comidas.map((comida, index) => {
                            return (
                                <div key={index} className="d-flex align-items-center">
                                    <div style={{backgroundColor: "#2B2B2B", color: "white", padding: "2px 5px", marginRight: "5px"}}>
                                        {comida.abreviacion}
                                    </div>
                                    {comida.name}
                                </div>
                            )
                        })}
                    </div>
                </div>  
            </div>
        )
    }

    renderInvitados = (invitados) => {
        const { user, eventSelected } = this.state
        const evento = this.props.eventoSP ? this.props.eventoSP : []

        return(
            <div>
                <div className="">
                    <div className="invitados-grid mt-3 resumen-invitados-card">
                        <div>
                            <div className="numero-invitados">
                                {invitados.filter(inv => inv.estado_id === 4 ).length}
                            </div>
                            <div className={"texto-invitados " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                CONFIRMADOS
                            </div>
                        </div>
                        <div>
                            <div className="numero-invitados">
                                {invitados.filter(inv => inv.estado_id === 3 ).length}
                            </div>
                            <div className={"texto-invitados " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                PENDIENTES
                            </div>
                        </div>
                        <div>
                            <div className="numero-invitados">
                                {invitados.filter(inv => inv.estado_id === 5 ).length}
                            </div>
                            <div className={"texto-invitados " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                NO VOY
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mt-3">
                        <input type="text" name="busqueda" autoComplete='off' className="w-100 input-busqueda" placeholder="BUSCAR" onChange={(e) => {
                            this.setState({
                                busqueda: e.target.value
                            })
                        }} />
                    </div>
                    <div className="mt-3" style={{
                        maxHeight: "350px",
                        overflowY: "auto"}}>
                        <table className="w-100">
                            <tbody className="tabla-invitados">
                                {
                                    invitados.filter(inv => JSON.stringify(inv).toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1).map((invitado, index) => {
                                        return(
                                            <tr className={(invitados[index+1] && invitados[index+1].parent_id) === invitado.id || (invitado.parent_id && (invitados[index+1] && invitados[index+1].parent_id) === invitado.parent_id) ? "acomp" : ""} key={index}>
                                                <td style={{width: "50px"}}> 
                                                    <div style={(user && user.permisos && user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_invitados") !== -1)) ? {} : {cursor: "default"}} className={"punto-presencia " + ((invitado.estado_id === 4) ? "verde" : (invitado.estado_id === 5 ? "rojo" : "amarillo"))} onClick={async () => {
                                                        if((user && user.permisos && user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_invitados") !== -1))) {

                                                            Swal.fire({
                                                                title: 'Cambiando estado.',
                                                                text: 'Está a punto de cambiar el estado de un invitado. ¿Desea continuar?',
                                                                showCancelButton: true,
                                                                confirmButtonText: 'Continuar',
                                                                showLoaderOnConfirm: true,
                                                                allowOutsideClick: () => !Swal.isLoading()
                                                            }).then((result) => {      
                                                                if (result.isConfirmed) {                         
                                                                    Swal.fire({
                                                                            title: 'Realizando cambios',
                                                                            // html: 'I will close in <b></b> milliseconds.',
                                                                            didOpen: async () => {
                                                                                Swal.showLoading()

                                                                                let newInvitado = invitado;
                                                                                if(invitado.estado_id === 4) {
                                                                                    newInvitado.estado_id = 3
                                                                                } else if(invitado.estado_id === 3) {
                                                                                    newInvitado.estado_id = 5
                                                                                } else if(invitado.estado_id === 5) {
                                                                                    newInvitado.estado_id = 4
                                                                                }

                                                                                delete newInvitado.place
                                                                                delete newInvitado.parent
                                                                                delete newInvitado.estado
                                                                                await this.actualizarFila(newInvitado, "Invitado")
                                                                                await this.props.getInvitados()

                                                                                Swal.clickConfirm()
                                                                            },
                                                                    }).then((result) => {
                                                                    /* Read more about handling dismissals below */
                                                                        console.log("terminado")
                                                                    })

                                                            }
                                                          })
                                                        }
                                                    }} >
                                                        
                                                    </div>
                                                </td>
                                                <td style={{width: "55px"}}>
                                                    {invitado.estado_id === 4 &&
                                                    <div style={{backgroundColor: "#2B2B2B", color: "white", padding: "2px 5px", width: "35px"}}>
                                                        {this.renderComida(invitado.comida_id)}
                                                    </div>
                                                    }
                                                </td>
                                                <td className="text-start" style={{marginLeft: "10px", paddingLeft: invitado.parent_id ? "20px" : ""}}> {invitado.parent_id && "- "} {invitado.name || (invitado.parent && ("Acomp. de " + invitado.parent.name))} </td>
                                                {/* <td className="gray4 table-mesa" > {invitado.mesa} </td> */}
                                                <td className="gray4"> {invitado.place_name} </td>
                                                <td className="table-enviar" >
                                                {invitado.telefono && invitado.hash && 
                                                    <a href={"https://wa.me/" + invitado.telefono + `?text=Te invito a mi fiesta el día
                                                    ${evento && evento.fechahora && evento.fechahora.getDate()} de 
                                                    ${evento && evento.fechahora && evento.fechahora.toLocaleString('default', { month: 'long' })} de 
                                                    ${evento && evento.fechahora && evento.fechahora.getFullYear()}.
                                                    Para confirmar tu presencia ingresá en el siguiente link: ${urlServer}/?hash=${invitado.hash}
                                                    `} target="_blank" rel="noreferrer">
                                                            <img src={whatsapp} style={{height: "25px", width: "25px"}} alt="whatsapp" />
                                                            {/* <ReactSVG src={whatsapp} style={{height: "25px", width: "25px"}}/> */}
                                                        {/* <div className="div-enviar">
                                                        </div> */}
                                                    </a>
                                                }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    
                    {(user && 
                    user.permisos && 
                    user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_invitados") !== -1)) 
                    &&
                    <div>
                        {/* <div className="d-flex justify-content-end my-3">
                            <div className="d-flex justify-content-end enviar-masivo">
                                <div className="mx-3 d-flex align-items-center">
                                    <span>
                                        ENVIAR RSVP
                                    </span>
                                </div>
                                <div>
                                    <div className="div-enviar">
                                        <ReactSVG src={enviar} style={{height: "13px", width: "13px"}}/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    }
                    {(user &&
                    user.permisos &&
                    user.permisos.find(e => e.permiso && e.permiso.indexOf("edit_invitados") !== -1)) 
                    &&
                        <div className="d-flex justify-content-around f-11 flex-wrap">
                        <div className="button-invitados m-auto mt-3">
                            <div onClick={() => {
                                                                                        

                                const selectF = () => {
                                    let div = []
                                    let max = parseInt(this.state.num)
                                    for(let i=0;i<max;i++) {
                                        div= [
                                            ...div,
                                            <div key={i} className="mt-3">
                                                <input id={"nombre_invitado"+i} type="text" placeholder={"Nombre acompañante "+(i+1)}/> 
                                            </div>
                                        ]
                                    }

                                    return(
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    Acompañantes
                                                </div>
                                                <select defaultValue={this.state.num} id="n_acomp" onChange={async (e) => {
                                                    const nombre_invitado = document.getElementById('nombre_invitado').value
                                                    const email = document.getElementById('email').value
                                                    const telefono = document.getElementById('telefono').value
                                                    const instagram = document.getElementById('instagram').value
                                                    await this.setState({
                                                        num: e.target.value
                                                    })
                                                    Swal.update({title: "Agregar invitado"})
                                                    let nuevoNombre = document.getElementById('nombre_invitado')
                                                    nuevoNombre.value = nombre_invitado
                                                    let nuevoEmail = document.getElementById('email')
                                                    nuevoEmail.value = email
                                                    let nuevoTelefono = document.getElementById('telefono')
                                                    nuevoTelefono.value = telefono
                                                    let nuevoInsta = document.getElementById('instagram')
                                                    nuevoInsta.value = instagram
                                                }}>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                            <div className="text-start mt-3">
                                            {
                                                div
                                            }
                                            </div>
                                        </div>
                                    )
                                }

                                Swal.fire({
                                    title: 'Agregar invitado',
                                    confirmButtonText: "Agregar",
                                    showCancelButton: true,
                                    cancelButtonText: "Cancelar",
                                    html: `
                                    <div>
                                        <div class="d-flex justify-content-between flex-wrap mb-3" >

                                                <input id="nombre_invitado" type=text class="input-modal" placeholder="Nombre"/> 
                                                <input id="email" type=text class="input-modal" placeholder="E-mail" /> 
                                        </div>
                                        <div class="d-flex justify-content-between flex-wrap mb-3" >

                                                <input id="telefono" type=text class="input-modal" placeholder="Teléfono"/> 
                                                <input id="instagram" type=text class="input-modal" placeholder="Instagram" /> 
                                        </div>
                                        <div class="d-flex justify-content-between flex-wrap " >
                                            <div id="select" class="w-100">
                                            </div>
                                        </div>
                                    </div>
                                    `,
                                    didRender: () => {
                                        ReactDOM.render(React.createElement(selectF, {}), document.getElementById("select"));
                                    },
                                    // didOpen: () => {
                                    //     Swal.showLoading()
                                    // },
                                    preConfirm: async () => {
                                        Swal.showLoading()
                                        const inputNombre = document.getElementById("nombre_invitado")
                                        const inputEmail = document.getElementById("email")
                                        const inputTelefono = document.getElementById("telefono")
                                        const inputInstagram = document.getElementById("instagram")
                                        const { num, user, eventSelected } = this.state
                                        let div = []
                                        for(let i=0;i<num;i++) {
                                            const acomp = document.getElementById("nombre_invitado"+i)
                                            div = [
                                                ...div,
                                                acomp.value
                                            ]
                                        }
                                        const date = new Date().getTime()
                                        const hash = date.toString(36)
                                        const data = {
                                            name: inputNombre.value,
                                            email: inputEmail.value,
                                            telefono: inputTelefono.value,
                                            instagram: inputInstagram.value,
                                            estado_id: 3,
                                            hash
                                        }
                                        const res = await this.agregarFila(data, "Invitado")
                                        if(res.insertedIDSys) {
                                            const relacionPrincipal = {
                                                invitado_id: res.insertedIDSys,
                                                event_id: user.event[eventSelected].id,
                                            }
                                            await this.agregarFila(relacionPrincipal, "invitados_events")
                                            for(let i=0;i<div.length;i++) {
                                                const acomp = {
                                                    name: div[i],
                                                    parent_id: res.insertedIDSys,
                                                    estado_id: 3,
                                                }
                                                const invitado = await this.agregarFila(acomp, "Invitado")
                                                if(invitado.insertedIDSys) {
                                                    const relacion = {
                                                        invitado_id: invitado.insertedIDSys,
                                                        event_id: user.event[eventSelected].id,
                                                    }
                                                    await this.agregarFila(relacion, "invitados_events")
                                                }
                                            }
                                        }
                                        await this.props.getInvitados()
                                    }
                                  }).then((result) => {
                                    /* Read more about handling dismissals below */
                                  })
                            }} >
                                Agregar
                            </div>
                        </div>
                        <input id="importarxls" className="d-none" type="file" onChange={async (e) => {
                            let reader = new FileReader()
                            reader.readAsArrayBuffer(e.target.files[0])
                            reader.onloadend = (ev) => {
                                let data = new Uint8Array(ev.target.result)
                                let workbook = XLSX.read(data, {type: 'array'})

                                workbook.SheetNames.forEach(async (sheetName) => {
                                    let keys = Object.keys(workbook.Sheets[sheetName]["A1"])
                                    for(let key of keys) {
                                        workbook.Sheets[sheetName]["A1"][key] = workbook.Sheets[sheetName]["A1"][key].toLowerCase()
                                        workbook.Sheets[sheetName]["B1"][key] = workbook.Sheets[sheetName]["B1"][key].toLowerCase()
                                    }
                                    let XL_row_object = await XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
                                    const invitadosExcel = XL_row_object
                                    // const { invitados } = this.state.data
                                    // let filtro = [
                                    //     ...invitados.filter(inv => !invitadosExcel.find(invExcel => invExcel.email === inv.email)),
                                    //     ...invitadosExcel,
                                    // ]
                                    const { user, eventSelected } = this.state
                                    for (let i = 0; i < invitadosExcel.length; i++) {
                                        
                                        const date = new Date().getTime()
                                        const hash = date.toString(36)
                                        let invitado = invitadosExcel[i];
                                        invitado.hash = hash
                                        invitado.estado_id = 3;
                                        invitado.comida_id = 1;
                                        invitado.event_id = user.event[eventSelected].id
                                        await this.ImportarInvitadoXls(invitado)
                                    }
                                    this.props.getInvitados()

                                })
                            }
                        }} />
                        <div className="m-auto mt-3 button-invitados" onClick={() => {
                            let invitadosExcel = invitados
                            invitadosExcel = invitadosExcel.map(invitado => {
                                const invExcel = {
                                    Nombre: invitado.name || (invitado.parent && ("Acomp. de " + invitado.parent.name)),
                                    Email: invitado.email && (invitado.email.toLowerCase() !== "null") ? invitado.email : "",
                                    Presente: invitado.estado && invitado.estado.name,
                                    Comida: this.state.comidas.find(comida => comida.id === invitado.comida_id)?.name || "Sin Restricciones"
                                }
                                return invExcel
                            })
                            let wb = XLSX.utils.book_new()
                            let ws = XLSX.utils.json_to_sheet(invitadosExcel)
                            let maximo = invitadosExcel.reduce((a,b) => a.Nombre.length > b.Nombre.length ? a : b);
                            ws["!cols"] = [{width: maximo.Nombre.length},{width: 20},{width: 12},{width: 15}];
                            XLSX.utils.book_append_sheet(wb, ws, "invitados");  
                            XLSX.writeFile(wb, "ExportInvitados.xlsx")
                        }}>
                            <div>
                                Exportar Excel
                            </div>
                        </div>
                        <div className="m-auto mt-3 button-invitados" onClick={() => {
                            const input = document.getElementById('importarxls')
                            if (input) { input.click() }
                        }}>
                            <div>
                                Importar Excel
                            </div>
                        </div>
                        
                        <div className="m-auto mt-3 modelo button-invitados">
                            <a
                                href={`${urlServer}/modelo/modelo.xlsx`}
                                rel="noreferrer"
                                target="_blank"
                                >
                                <div>
                                    Modelo para importar
                                </div>
                            </a>
                        </div>
                    </div>}
                </div>
            </div>)
    }

    
    // a little function to help us with reordering the result
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
  
      let items = this.reorder(
        this.state.timeline,
        result.source.index,
        result.destination.index
      );

      items = items.map((item, index) => {
        if(item.order!==index) {
            item.order = index
            this.actualizarFila(item, "Timeline")
        }
        
        return item
      })

      this.setState({
        timeline: items,
      });
    }

    renderItem = (momento, index) => {
        const { user, eventSelected } = this.state
        return (<div key={index} className={"text-left w-48 card-timeline border" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
        <div className="d-flex" style={{color: 'white'}}>
            <div className="d-flex justify-content-center align-items-center text-center hora-inicio">
                <h4 className="m-0">
                    { this.mostrarHora(momento, index) }
                </h4>
            </div>
            <div className="d-flex" style={{
                borderBottom: "1px solid rgba(255,255,255,0.3)",
                padding: "15px 0",
                marginLeft: "15px",
                width: "100%"
            }}>

                <div className="texto-timeline">
                    <div className="d-flex titulo-timeline">
                        {
                            momento.editando ?
                            <>
                                    Nombre: 
                                <div className="ps-3">
                                    <input type="text" className={"input-nuevotimeline w-100 mb-4 border" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} value={this.state.timelinesEditando[index] && this.state.timelinesEditando[index].title} onChange={(e) => {
                                        let { timelinesEditando } = this.state
                                        timelinesEditando[index] = {
                                            ...timelinesEditando[index],
                                            title: e.target.value
                                        }
                                        this.setState({
                                            timelinesEditando
                                        })
                                    }} />
                                </div>
                            </>
                        :
                        <h4 className="text-start">{momento.title}</h4>
                        }
                    </div>
                    <div className={"text-start hora-timeline d-flex fw-400 f-14 " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                        
                    {
                            momento.editando ?
                            <>
                                Duracion: 
                                <input type="number" className={"input-nuevotimeline w-25 border" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} value={this.state.timelinesEditando[index] && this.state.timelinesEditando[index].duracion} onChange={(e) => {
                                    let { timelinesEditando } = this.state
                                    timelinesEditando[index] = {
                                        ...timelinesEditando[index],
                                        duracion: parseInt(e.target.value)
                                    }
                                    this.setState({
                                        timelinesEditando
                                    })
                                }} />
                                minutos
                            </>
                        :
                        <>
                            <ReactSVG src={reloj} />

                            {this.mostrarDuracion(momento)}
                        </>
                        }

                    </div>
                </div>
                {(user && user.permisos && user.permisos.find(e => e.permiso && e.permiso.indexOf("add_timeline") !== -1)) &&
                <div className="py-3 ms-auto">
                    <div className="d-flex">
                        {momento.editando ? 
                        
                        <div className={"ms-3 me-4 imgsvg back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} onClick={async () => {
                            let { timelinesEditando, timeline } = this.state
                            const keys = Object.keys(timelinesEditando[index])
                            for(let key of keys) {
                                timeline[index][key] = timelinesEditando[index][key]
                            }
                            delete timelinesEditando[index]
                            delete timeline[index].editando
                            this.setState({
                                timelinesEditando,
                            })
                            await this.actualizarFila(timeline[index], "Timeline")
                            this.getTimeline()
                        }} >
                            <img src={guardar} alt="Guardar" style={{width: "13px", height: "13px", color: "white", marginTop: "-1px"}} />
                        </div>
                        :<div className={"ms-3 me-4 imgsvg back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} onClick={() => {
                            let { timeline,timelinesEditando } = this.state
                            momento.editando = true
                            timeline[index] = momento
                            timelinesEditando[index] = momento
                            this.setState({
                                timeline,
                                timelinesEditando,
                            })
                        }} >
                            <img src={editar} alt="Editar" style={{width: "13px", height: "13px"}} />
                        </div>
                        }
                        <div 
                        className={"imgsvg back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} 
                        onClick={async () => {
                            Swal.fire({
                                title: 'Eliminando ítem.',
                                text: '¿Desea eliminar este ítem?',
                                showCancelButton: true,
                                confirmButtonText: 'Continuar',
                                cancelButtonText: 'Cancelar',
                                showLoaderOnConfirm: true,
                                allowOutsideClick: () => !Swal.isLoading()
                            }).then((result) => {      
                                if (result.isConfirmed) {                          
                                    Swal.fire({
                                            title: 'Eliminando ítem',
                                            // html: 'I will close in <b></b> milliseconds.',
                                            didOpen: async () => {
                                                Swal.showLoading()
                                                await this.eliminar(momento.id, "Timeline")

                                                Swal.clickConfirm()
                                            },
                                    }).then((result) => {
                                    /* Read more about handling dismissals below */
                                        this.getTimeline()
                                    })

                                }
                            })
                        }}>
                            <img src={borrar} alt="Borrar" style={{width: "13px", height: "13px"}} />
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>)
    }

    renderTimeline = () => {
        const { timeline, user } = this.state
        const permisosAgregarTimeline = (user && user.permisos && user.permisos.find(e => e.permiso && e.permiso.indexOf("add_timeline") !== -1))

        let acumulado = 0

        const array = permisosAgregarTimeline ? timeline.map((momento, index) => {
                acumulado += momento.duracion

                return (<Draggable key={momento.id} draggableId={momento.id && momento.id.toString()} index={index}>
                    {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {this.renderItem(momento, index)}
                    </div>
                    )}
                </Draggable>
                )
            })

             :
            timeline.map((momento, index) => {
                acumulado += momento.duracion
                return (
                this.renderItem(momento, index)
            )})

            
        let mostrarAcumulado = moment("00:00", "hh:mm").add(acumulado, "minutes").format("HH:mm")

        return  (
                <div>
                    <h6 className="my-3">
                        Tiempo Estimado: {mostrarAcumulado}
                    </h6>
                    {(permisosAgregarTimeline) ?
                    <>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <div className="droptimeline">
                                        {array}
                                    </div>
                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <AgregarItemTimeline 
                            user={this.props.user}
                            crearDnd={this.crearDnd}
                            getTimeline={this.getTimeline}
                        />
                    </>:
                    <div>
                        {array}
                    </div>
                    }
                </div>
        )
    }

    componentDidMount() {
        const { user } = this.props;
        if(user && user.permisos) {
            this.getTimeline()
        }
        this.getComidas()
    }

    render() {
        const { tabinvitados, tabtimeline,
            data } = this.state

        const { invitados } = this.props

        return(
            <>
                <div className="d-flex tabs mx-auto justify-content-around p-1">
                    <div className={"tab px-3 px-md-5" + (tabinvitados? " active" : "")} onClick={() => {
                        this.setState({
                            tabinvitados: true,
                            tabtimeline: false,
                            tabmesas: false,
                        })
                    }}>
                        INVITADOS
                    </div>
                    <div className={"tab px-3 px-md-5" + (tabtimeline? " active" : "")} onClick={() => {
                        this.setState({
                            tabinvitados: false,
                            tabtimeline: true,
                            tabmesas: false,
                        })
                    }}>
                        TIMMING
                    </div>
                </div>
                {tabinvitados && this.renderInvitados(invitados)}
                {tabtimeline && this.renderTimeline()}
                {tabinvitados && this.renderReferencias()}
            </>
        )
    }

}