import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Thumbs, Controller } from 'swiper';

import Swal from 'sweetalert2'

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import { postmanejado } from './fetchmanejado';

export default class Fotos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedImage: null,
          thumbsSwiper: null,
          activeIndex: 0,
          primerSwiper: null,
          images: [],
          selectedImageIndex: 0
        };
        this.carouselRef = React.createRef();
    }
    
    handleSlide = (e) => {
        this.setState({ selectedImageIndex: e.to });
    };
    
    selectImage = async (image, index = 0) => {
        console.log(image, index);
        const selectedImageIndex = index.toString();
        await this.setState({ selectedImageIndex: selectedImageIndex});
        this.setState({
            selectedImage: image
        });
    };
    
    closeImage = () => {
        this.setState({ selectedImage: null, selectedImageIndex: 0, thumbsSwiper: null});
    };
    
    removeSelectedImage = async (event_id, foto_id) => {
        let data = { 
            event_id,
            foto_id
        };
        const response = await postmanejado("/api/auth/eliminarFoto", data, true);
        return response;
    }


    getDataInvitado = (id) => {
        let invitado;
        if(this.props.invitados) {
            invitado = this.props.invitados.find(inv => inv.id === parseInt(id));
        }
        return invitado;
    }

    getFotos = async (event_id) => {
        let data = { event_id };
        const { fotos} = await postmanejado("/api/auth/listFotosEvento", data, true);
        return fotos;
    }

    init = async (event_id) => {
        const images = await this.getFotos(event_id);
        this.setState({
            images
        })
        let nuevasFotos = false;
        let haceUnaSemana = new Date();
        haceUnaSemana.setDate(haceUnaSemana.getDate() - 7);
        if(images.filter(image => {
            let fechaCreacion = new Date(image.created_at);
            return fechaCreacion >= haceUnaSemana;
        }).length > 0)
        {
            nuevasFotos = true;
        }
        if(this.props?.setNotif) {
            this.props.setNotif({
                'Fotos': nuevasFotos
            })
        }
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.user && this.props.eventSelected !== null && this.props.eventSelected !== undefined && this.props.user.event && this.props.user.event[this.props.eventSelected] &&
        (this.props.user !== prevProps.user || this.props.eventSelected !== prevProps.eventSelected)) {
            const event_id = this.props.user.event[this.props.eventSelected].id;
            this.init(event_id);
        }

    }
    
    componentDidMount() {
        if (this.carouselRef.current) {
            this.carouselRef.current.addEventListener('slid.bs.carousel', this.handleSlide);
        }

        if (this.props.user && this.props.eventSelected !== null && this.props.eventSelected !== undefined && this.props.user.event && this.props.user.event[this.props.eventSelected]) {
            const event_id = this.props.user.event[this.props.eventSelected].id;
            this.init(event_id);
        }
    }
    
    componentWillUnmount() {
        if (this.carouselRef.current) {
            this.carouselRef.current.removeEventListener('slid.bs.carousel', this.handleSlide);
        }
    }

  
    render() {

        return (
            <div id="fotos_usuario" className="text-start">
                <div className="position-relative text-center">
                    <div className="grid-fotos">
                        {this.state.images.slice(0,4).map((image, index) => (
                            <img src={'https://drive.google.com/thumbnail?sz=w500&id=' + image.file_id} alt={image.file} key={index} className="img-fluid"
                                onClick={() => this.selectImage(image, index)}/>
                        ))}
                    </div>
                    <small className="mt-2 d-flex justify-content-center"><i>Haga click sobre las fotos para ver toda la galería.</i></small>
                </div>

            {this.state.selectedImage && (
            <div
            className="modal show"
            tabIndex="-1"
            style={{
                display: 'block',
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 1050
            }}
            onClick={this.closeImage}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg mx-auto" onClick={(e) => e && e.stopPropagation && e.stopPropagation()}>
                    <div className="modal-content" style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <button type="button" className="btn-close" onClick={this.closeImage}></button>
                        <Swiper 
                            modules={[Thumbs, Navigation, Autoplay, Controller]} 
                            thumbs={{ swiper: this.state.thumbsSwiper, slideThumbActiveClass: "slide-img-active"}}
                            navigation
                            // autoplay
                            initialSlide={this.state.selectedImageIndex || "0"}
                            controller={{ control: this.state.thumbsSwiper }}
                            className="principal-swiper"
                        >
                        {this.state.images.map((image, index) => {
                            
                            return (
                            <SwiperSlide key={image.id}>
                                <div className="mx-auto" id={"slide-principal-" + index}>
                                    <img src={'https://drive.google.com/thumbnail?sz=w500&id=' + image.file_id} alt={image.file} className="w-100" onLoad={() => {
                                        if (!image.loaded) {
                                            const { images } = this.state
                                            images[index].loaded = true;
                                            this.setState({
                                                images
                                            })
                                        }
                                    }} />
                                    {image.loaded &&
                                    <div className="text-center mt-3">
                                        <h6> 
                                        {image.invitado.name}
                                        </h6>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => 
                                                
                                                Swal.fire({
                                                    title: 'Eliminando imagen',
                                                    // html: 'I will close in <b></b> milliseconds.',
                                                    didOpen: async () => {
                                                        const event_id = this.props.user.event[this.props.eventSelected].id;
                                                        Swal.showLoading()
                                                        let prevIndex = index - 1;
                                                        const imagesOld = this.state.images
                                                        const response = await this.removeSelectedImage(event_id, image.id);
                                                        if(response && response.message === true) {
                                                            await this.init(event_id)
                                                            if(this.state.images[prevIndex]) {
                                                                const { images } = this.state;
                                                                images.forEach(image => {
                                                                    if(imagesOld.find(imgOld => imgOld?.id === image?.id)) {
                                                                        image.loaded = true;
                                                                    }
                                                                })
                                                                await this.setState({
                                                                    images
                                                                })
                                                                this.selectImage(this.state.images[prevIndex], prevIndex)
                                                            } else {
                                                                this.closeImage();
                                                            }
                                                        }
                                                        Swal.clickConfirm()
                                                    },
                                                })
                                            }
                                        >
                                            Eliminar esta imagen
                                        </button>
                                    </div>
                                    }
                                </div>
                            </SwiperSlide>
                        )})}
                        </Swiper>

                        <Swiper 
                            modules={[Thumbs, Controller]}
                            spaceBetween={20}
                            slidesPerView={this.state.images.length < 3 ? this.state.images.length : 3}
                            breakpoints={{
                                640: {
                                    slidesPerView: this.state.images.length < 5 ? this.state.images.length : 5,
                                    spaceBetween: 30
                                }
                            }}
                            centeredSlides
                            onSwiper={(swiper) => this.setState({thumbsSwiper: swiper})}
                            className="thumb-fotos"
                        >
                            {this.state.images.map((image, index) => (
                                <SwiperSlide key={image.id}>
                                    <img src={'https://drive.google.com/thumbnail?sz=w500&id=' + image.file_id} alt={image.name} className="d-block w-100 img-thumb" style={{width: "200px"}} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    </div>
                </div>
                )}
            </div>
        );
    }

}