import { postmanejado } from './fetchmanejado'
import Swal from 'sweetalert2'
import { ReactSVG } from 'react-svg'
import withReactContent from 'sweetalert2-react-content'
import configuracionsvg from './assets/configuracion.svg'
import Select from 'react-select'

const MySwal = withReactContent(Swal)

const editarTabla = async (user, eventSelected) => {
    let { pivot, modulos, modulos_contratados, ...event } = user.event[eventSelected]
    let data = {
        id: user.event[eventSelected].id,
        axn: "update",
        fields: JSON.stringify(event),
        entity: "Event"
    }
    let response = await enviarData(data)
    return await response
}

const enviarData = async (data) => {
    let response
    await Swal.fire({
            title: 'Realizando cambios',
            didOpen: async () => {
                Swal.showLoading()
                response = await postmanejado("/api/auth/abmRowFromModel", data, true)
                Swal.clickConfirm()
            },
    })
    if(response.response===true) {
        Swal.fire({
            icon: 'success',
            title: 'Se han guardado los cambios con éxito',
            confirmButtonText: 'Aceptar'
        })
    }
    return await response
}

const cambiarCombinacion = async (fondo, color, user, eventSelected, consultarUsuario, setState) => {
    user.event[eventSelected] = {
        ...user.event[eventSelected],
        custom_background: fondo,
        custom_color: color
    }
    await setState({
        user
    })
    await editarTabla(user, eventSelected)
    await consultarUsuario(eventSelected)
}

export default function configuracion (props) {
    const { user, eventSelected, diaDelEvento, calculadora, setState, iniciar, setEvent, setBackground, consultarUsuario, loggingOut } = props
    let currentfondo

    if(user.event[eventSelected]) {
        currentfondo = user.event[eventSelected].custom_background
    }
    return(
            <div style={{position: "absolute", right: "0"}}>
                <div className="dropdown">
            
                    <div  type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <ReactSVG src={configuracionsvg} />
                    </div>
                    
                    <div className="dropdown-menu" aria-labelledby="dropdownMenu2" style={{ borderRadius: "20px", padding: "0.5rem"}}>
                        <div className="p-3 config-tab settings-card" style={{color: "black"}}>
                        
                        {(user.event.length > 1) &&
                        <>

                            <div className="f-13 text-start mb-3 gray4">
                                ELEGIR EVENTO
                            </div>
                            <div>
                                <div className="d-flex justify-content-between my-2" onClick={e => e.stopPropagation()}>
                                    <Select 
                                        options={user.event.map((ev, index) => { return {value: index, label: ev.title }})} 
                                        styles={{
                                            container: (base, state) => ({
                                                ...base,
                                                width: "100%",
                                            }),
                                            indicatorSeparator: (base, state) => ({
                                                ...base,
                                                display: "none",
                                            }),
                                            indicatorContainer: (base, state) => ({
                                                ...base,
                                                padding: "0",
                                                paddingRight: "0.5rem",
                                            }),
                                        }}
                                        placeholder="Seleccionar evento"
                                        defaultValue={user.event.map((ev, index) => { return {value: index, label: ev.title }})[eventSelected || 0]}
                                        onChange={async (e) => {

                                            await setState({
                                                loading: true,
                                                diaDelEvento: false,
                                                calculadora: false,
                                            })
                                            await setEvent(e.value)
                                            if(user.event[e.value] && user.event[e.value].custom_background) {
                                                await setBackground(user.event[e.value].custom_background)
                                            }
                                            document.getElementById("dropdownMenu2").click()
                                            await iniciar()
                                            setState({
                                                loading: false,
                                                diaDelEvento,
                                                calculadora
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <hr />
                        </>
                        }
                        
                        {(user.permisos?.find(e => e.permiso && e.permiso.indexOf("edit_settings") !== -1)) &&
                        (user.event?.length > 0) &&
                        <>

                            <div className="f-13 text-start mb-3 gray4">
                                PERSONALIZAR SITIO
                            </div>
                            <div>
                                <div className="f-13 text-start mb-2 fw-500">
                                    FONDOS
                                </div>
                                <div>
                                    <div className="d-flex justify-content-between my-2" >
                                        <div className="mx-1 w-50" style={{cursor: "pointer"}} onClick={async () => {
                                            cambiarCombinacion("background1", "settings-naranja", user, eventSelected, consultarUsuario, setState)
                                        }}>
                                            <div className="settings-background background1" style={{border: currentfondo === "background1"? '2px solid black' : ''}} />
                                        </div>
                                        <div className="mx-1 w-50" style={{cursor: "pointer"}} onClick={async () => {
                                            cambiarCombinacion("background2", "settings-celeste", user, eventSelected, consultarUsuario, setState)
                                        }}>
                                        <div className="settings-background background2" style={{border: currentfondo === "background2"? '2px solid black' : ''}} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between my-2" >
                                        <div className="mx-1 w-50" style={{cursor: "pointer"}} onClick={async () => {
                                            cambiarCombinacion("background3", "settings-verde", user, eventSelected, consultarUsuario, setState)
                                        }}>
                                            <div className="settings-background background3" style={{border: currentfondo === "background3"? '2px solid black' : ''}} />
                                        </div>
                                        <div className="mx-1 w-50" style={{cursor: "pointer"}} onClick={async () => {
                                            cambiarCombinacion("background4", "settings-amarillo", user, eventSelected, consultarUsuario, setState)
                                        }}>
                                            <div className="settings-background background4" style={{border: currentfondo === "background4"? '2px solid black' : ''}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="dropdown-divider" />
                        </>
                        }
                        <div className="text-start f-12" style={{cursor: "pointer"}} onClick={() => {
                                    MySwal.fire({
                                        title:"Editar invitado",
                                        html:`
                                            <div class="text-start">
                                                <label class="form-label mb-3">Nombre:</label>
                                                    <div class=" mb-4">
                                                        <strong>${user.name}</strong>
                                                    </div>
                                                <label class="form-label mb-3">E-mail:</label>
                                                    <div class=" mb-4">
                                                        <strong>${user.email}</strong>
                                                    </div>
                                                <form autocomplete="off" method="post" action="">
                                                    <input autocomplete="false" name="hidden" type="text" style="display:none;">
                                                    <label class="form-label mb-3">Nueva contraseña:</label>
                                                    <input id="pass1" name="pass1" class="form-control mb-4" autocomplete="off2" type="password" placeholder="Nueva contraseña" />
                                                    <label class="form-label mb-3">Confirme nueva contraseña:</label>
                                                    <input id="pass2" name="pass2" class="form-control mb-4" autocomplete="off2" type="password" placeholder="Confirme nueva contraseña" />
                                                    <div id="warning" class="alert alert-danger" role="alert" style="display:none">
                                                        Las contraseñas deben coincidir.
                                                    </div>
                                                </form>
                                            </div>
                                        `,
                                        showCancelButton: true,
                                        cancelButtonText: "Cerrar",
                                        confirmButtonText: "Guardar",
                                        preConfirm: async () => {
                                            let pass1 = document.getElementById('pass1').value
                                            let pass2 = document.getElementById('pass2').value
                                            if(pass1 !== pass2){
                                                document.getElementById('warning').style = ""
                                                return false
                                            }
                                            let data = {
                                                user_edpro: user.user_edpro,
                                                pass: pass1,
                                            }
                                            let response = await postmanejado("/api/auth/setUserPass", data, true)
                                            .then(res => {
                                                MySwal.fire({
                                                    icon: 'success',
                                                    title: 'Se han guardado los cambios con éxito',
                                                    confirmButtonText: 'Aceptar'
                                                })
                                                return res
                                            })
                                            return await response
                                        },
                                    })
                        }}>
                            VER PERFIL
                        </div>
                        <hr className="dropdown-divider" />
                        <div className="text-start f-12" style={{cursor: "pointer"}} onClick={() => {
                            loggingOut()
                        }}>
                            CERRAR SESIÓN
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}