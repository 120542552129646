import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import confirmado from './assets/confirmado.svg'
import pendiente from './assets/pendiente.svg'
import novoy from './assets/novoy.svg'
import editar from './assets/editar.svg'

import urlServer from './server'
import { postmanejado } from './fetchmanejado'
import { ItemAccordion } from './accordion';
import Musica from './musica_invitado.js'
import Trivia from './trivia_invitado.js'
import Fotos from './fotos_invitado.js'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)



const modulosComponents = {
    'Musica': Musica,
    'Trivia': Trivia,
    'Fotos': Fotos
  };  

function compareObjects(obj1, obj2) {
    // Comprobar si los objetos tienen el mismo número de propiedades
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }

    // Comprobar si los objetos tienen las mismas propiedades y valores
    for (let key in obj1) {
        if (key !== 'event' && obj1.hasOwnProperty(key)) {
            if (!obj2.hasOwnProperty(key)) {
                return false;
            }
            if(obj1[key] === "null" && !obj2[key]) {
                return true;
            }
            if(obj2[key] === "null" && !obj1[key]) {
                return true;
            }
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
    }

    // Si llegamos hasta aquí, los objetos son iguales
    return true;
}

function compareObjectArrays(arr1, arr2) {
    // Comprobar si los arrays tienen la misma longitud
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Ordenar los arrays por id
    arr1.sort((a, b) => a.id - b.id);
    arr2.sort((a, b) => a.id - b.id);

    // Comprobar si los elementos de los arrays son iguales
    for (let i = 0; i < arr1.length; i++) {
        if (!compareObjects(arr1[i], arr2[i])) {
            return false;
        }
    }

    // Si llegamos hasta aquí, los arrays son iguales
    return true;
}

export default class Invitado extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statusSelected: "",
            comidaSelected: "",
            comidas: [],
            invitadosGuardados: [],
            invitados: this.props.invitados ? this.props.invitados : [],
            fechaLimiteExcedida: false,
            loaded: false,
            update: 0,
        }
    }

    confirmarCambio = async (invitados) => {
        
        Swal.fire({
            title: 'Cambiando estado.',
            text: 'Está a punto de cambiar el estado de los invitados. ¿Desea continuar?',
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {      
            if (result.isConfirmed) {                          
                Swal.fire({
                    title: 'Realizando cambios',
                    didOpen: async () => {
                        Swal.showLoading()

                        const { user } = this.props
                        for (let i = 0; i < invitados.length; i++) {
                            let invitado = invitados[i];
                            invitado.auth_id = user?.id;
                            invitado.auth_hash = user?.hash;
                            delete invitado.place_name
                            await this.cambiarEstado(invitado)
                        }
                        const params = new URLSearchParams(window.location.search)
                        const hash = params.get("hash")
                        await this.props.consultarInvitado(hash)
                        this.setState({
                            invitadosGuardados: JSON.parse(JSON.stringify(this.state.invitados))
                        })
                        Swal.clickConfirm()
                    },
                }).then((result) => {
                /* Read more about handling dismissals below */
                    console.log("terminado")
                })
            }
        })
    }

    cambiarEstado = async (invitado) => {
        let data = invitado
        data.invitado_id = data.id
        let response = await postmanejado("/api/updateInvitado", data)
        return await response
    }

    editarPerfil = async (invitado) => {
        await Swal.fire({
            title: 'Realizando cambios',
            didOpen: async () => {
                Swal.showLoading()
                await this.cambiarEstado(invitado)
                Swal.clickConfirm()
            },
        })
        const params = new URLSearchParams(window.location.search)
        const hash = params.get("hash")
        return await this.props.consultarInvitado(hash)
    }

    seExcedioFechaLimite = () => {
        const { user, eventSelected } = this.props
        let fechaLimiteExcedida = false
        if(new Date(user && user.event[eventSelected] && user.event[eventSelected].fechaCierre) > new Date()) {
        } else {
            fechaLimiteExcedida = true
        }
        return fechaLimiteExcedida
    }

    getComidas = async () => {
        let comidas = await fetch(`${urlServer}/api/getComidas`)
        return await comidas.json()
    }

    init = async () => {
        const fechaLimiteExcedida = this.seExcedioFechaLimite();
        const comidas = await this.getComidas();
        this.setState({
            fechaLimiteExcedida,
            comidas,
            loaded: true
        })
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate() {
        if (this.state.invitadosGuardados.length === 0 && this.props.invitados.length > 0 && !this.state.loaded ) {
            this.setState({
                loaded: true,
                invitadosGuardados: JSON.parse(JSON.stringify(this.props.invitados))
            })
        }
    }
    
    editarModal = async (invitado) => {
        return await MySwal.fire({
            title:"Editar invitado",
            html:`
                <div class="text-start">
                    <label class="form-label mb-3">Nombre:</label>
                    ${
                        invitado.name ?
                        `<div class=" mb-4">
                            <strong>${invitado.name}</strong>
                        </div>`
                        :
                        `<input id="name" class="form-control mb-4" type="text" placeholder="Nombre" />`
                    }
                    <label class="form-label mb-3">E-mail:</label>
                    ${
                        invitado.email && invitado.email !== "null" ?
                        `<div class=" mb-4">
                            <strong>${invitado.email}</strong>
                        </div>`
                        :
                        `<input id="email" class="form-control mb-4" type="email" placeholder="Email" />`
                    }
                    <label class="form-label mb-3">Instagram:</label>
                    <input id="instagram" class="form-control mb-4" type="text" value=${(invitado.instagram !== "null" && invitado.instagram) || `""`} placeholder="Instagram" />
                    <label class="form-label mb-3">Teléfono:</label>
                    <input id="telefono" class="form-control mb-4" type="text" value=${(invitado.telefono !== "null" && invitado.telefono) || `""`} placeholder="Teléfono" />
                </div>
            `,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Guardar",
            preConfirm: async () => {
                let name = (document.getElementById('name') && document.getElementById('name').value) || invitado.name
                let email = (document.getElementById('email') && document.getElementById('email').value) || invitado.email
                let instagram = document.getElementById('instagram').value
                let telefono = document.getElementById('telefono').value
                const { user } = this.props
                let updateInvitado = {
                    auth_id: user?.id,
                    auth_hash: user?.hash,
                    id: invitado.id,
                    estado_id: invitado.estado_id,
                    name,
                    email,
                    instagram,
                    telefono,
                }
                const result = await this.editarPerfil(updateInvitado)
                this.setState({
                    invitados: typeof(result) === "object" ? result : this.props.invitados,
                    invitadosGuardados: typeof(result) === "object" ? JSON.parse(JSON.stringify(result)) : JSON.parse(JSON.stringify(this.props.invitados)),
                })
            },
        })
    }

    render() {
        const { invitados, invitadosGuardados, fechaLimiteExcedida } = this.state
        const { user, eventSelected } = this.props

        let modulos = user?.event[eventSelected]?.modulos || []
        modulos = modulos.map((modulo,index) => modulos.findIndex((mod) => mod.nombre === modulo.nombre) === index && ({
            nombre: modulo.nombre,
            titulo: modulo.titulo,
            Componente: modulosComponents[modulo.nombre]
        })).filter(modulo => modulo.Componente);

        return(
            <div className="my-4 col-md-8 mx-auto" style={{color: "white"}}>
                <div className="my-3 f-14">
                    MI PRESENCIA EN LA FIESTA
                </div>

                {fechaLimiteExcedida &&
                    <p className="f-12 italic">
                        Se ha excedido la fecha límite para modificar su respuesta. Para realizar algún cambio contactese con el organizador del evento.
                    </p>
                }
                {invitados.map((invitado, index) => {
                    let invitadoGuardado = invitadosGuardados.find(inv => inv.id === invitado.id);
                    return (

                        <div className="my-3" key={index} >
                            <div className="text-start mb-3 d-flex">
                                { (invitado && invitado.name) || "Acompañante" }
                                <div className={"ms-3 me-4 imgsvg back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} >
                                    <img 
                                        src={editar}
                                        alt="Editar"
                                        onClick={() => this.editarModal(invitadoGuardado)}
                                        style={{width: "13px", height: "13px"}} 
                                    />
                                </div>
                            </div>
                            <div className={"d-flex justify-content-around f-13 px-2 py-2 confirmacion-invitados back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")} >
                                <div className="d-flex align-items-center" style={{cursor: fechaLimiteExcedida? null : "pointer"}} onClick={() => {
                                    if(!fechaLimiteExcedida) {
                                        let { invitados } = this.state
                                        invitados[index].estado_id = 4
                                        this.setState({
                                            invitados
                                        })
                                    }
                                }}>
                                    <ReactSVG src={confirmado} style={{height: '19px', width: '17px', marginRight: '6px', color: (invitado && invitado.estado_id === 4) ? "white" : "#050520"}} />
                                    Confirmado
                                </div>
                                <div className="d-flex align-items-center" style={{cursor: fechaLimiteExcedida? null : "pointer"}} onClick={() => {
                                    if(!fechaLimiteExcedida) {
                                        let { invitados } = this.state
                                        invitados[index].estado_id = 3
                                        this.setState({
                                            invitados
                                        })
                                    }
                                }}>
                                    <ReactSVG src={pendiente} style={{height: '19px', width: '17px', marginRight: '6px', color: (invitado && invitado.estado_id === 3) ? "white" : "#050520"}} />
                                    Pendiente
                                </div>
                                <div className="d-flex align-items-center" style={{cursor: fechaLimiteExcedida? null : "pointer"}} onClick={() => {
                                    if(!fechaLimiteExcedida) {
                                        let { invitados } = this.state
                                        invitados[index].estado_id = 5
                                        this.setState({
                                            invitados
                                        })
                                    }
                                }}>
                                    <ReactSVG src={novoy} style={{height: '19px', width: '17px', marginRight: '6px', color: (invitado && invitado.estado_id === 5) ? "white" : "#050520"}} />
                                    No voy
                                </div>
                            </div>

                            {(invitado && invitado.estado_id === 4) && 
                                <div className="mt-3">
                                    <div className="card p-3 tabcomidas ">
                                        <h6 className={"text-start mb-3 " + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                            Restricción en la comida
                                        </h6>
                                        <div className="d-grid flex-wrap tabcomida">
                                            <div className="d-flex align-items-center select-comida" style={{cursor: fechaLimiteExcedida? null : "pointer"}} onClick={() => {
                                                if(!fechaLimiteExcedida) {
                                                    let { invitados } = this.state
                                                    invitados[index].comida_id = "null"
                                                    this.setState({
                                                        invitados
                                                    })
                                                }
                                            }}>
                                                <ReactSVG src={confirmado} className="svg-tick-invitado" style={{color: (invitado && (!invitado.comida_id || invitado.comida_id==="null")) ? "white" : "#050520"}} />
                                                Sin restricción
                                            </div>

                                            {this.state.comidas.map((comida, ind) => {
                                                return (

                                                    <div key={ind} className="d-flex align-items-center select-comida" style={{cursor: fechaLimiteExcedida? null : "pointer"}} onClick={() => {
                                                        if(!fechaLimiteExcedida) {
                                                            let { invitados } = this.state
                                                            invitados[index].comida_id = comida.id
                                                            this.setState({
                                                                invitados
                                                            })
                                                        }
                                                    }}>
                                                        <ReactSVG src={confirmado} className="svg-tick-invitado" style={{ color: (invitado && invitado.comida_id === comida.id) ? "white" : "#050520"}} />
                                                        {comida.name}
                                                    </div>
                                                )
                                        
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                            <hr />
                        </div>
                    )
                })}

                {!fechaLimiteExcedida && !compareObjectArrays(invitados, invitadosGuardados) &&
                    <div className="d-flex justify-content-center boton-vermas-historial position-fixed" onClick={() => {
                            if(!fechaLimiteExcedida) {
                                let { invitados } = this.state
                                this.confirmarCambio(invitados)
                            }
                        }}>
                            <div className="position-relative w-100 d-flex justify-content-center align-items-center">
                                    {invitados.filter(inv => inv && !inv.name && (inv.estado_id===4)).length === 0 ?
                                        <div className={"boton-notificaciones d-flex align-items-center justify-content-center back" + ((user && user.event && user.event[eventSelected] && user.event[eventSelected].custom_color) || "amarillo")}>
                                                <span>
                                                    Guardar
                                                </span>
                                        </div>:
                                        <span>
                                            Ingrese los nombres de sus acompañantes
                                        </span>
                                    
                                    }
                            </div>
                        </div>
                }

                <div className="accordion accordion-flush" id="accordionFlushExample">
                    
                    {modulos.map((modulo, index) => {
                            let props = { user, eventSelected: eventSelected, fechaLimiteExcedida: fechaLimiteExcedida};
                            return <ItemAccordion
                                key={index}
                                titulo={modulo.titulo.toUpperCase()}
                                index={index+2}
                                user={user}
                                eventSelected={eventSelected}
                                fechaLimiteExcedida={fechaLimiteExcedida}
                                body={
                                    <modulo.Componente key={index} {...props} />
                                }
                            />;
                        })
                    }
                </div>
            </div>
        )
    }

}