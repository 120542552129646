import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { postmanejado } from './fetchmanejado';

import brandLogo from './assets/brandlogo.png'
import usuarioSVG from './assets/usuario.svg'
import candadoSVG from './assets/candado.svg'
import ojopassword from './assets/ojopassword.svg'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)



export default class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            email: '',
            password:'',
            isLogin: true,
            alert: [],
            successRecover: false,
            eventListener: false,
            params: new URLSearchParams(window.location.search),
        }
        // this.submit = this.submit.bind(this)
    }

    // Validador del usuario y contraseña

    validateReq = (data) => {
        let alert = []
        let valid = true
        if (!data.email) {
            alert = [
                ...alert,
                'Debe ingresar un e-mail',
            ]
            valid = false
        }
        if (!this.state.isLogin && !data.email) {
            alert = [
                ...alert,
                'Debe ingresar un e-mail',
            ]
            valid = false
        }
        if (!data.password && data.password !== undefined && this.state.isLogin) {
            alert = [
                ...alert,
                'Debe ingresar una contraseña',
            ]
            valid = false
        }
        if (!valid) {
            this.setState({
                alert
            })
            let string = ""
            for (let i = 0; i < alert.length; i++) {
                const al = alert[i];
                string += `<li>${al}</li>`
            }
            console.log(string)
            MySwal.fire({
                icon: 'error',
                title: string ? `<ul>${string}</ul>` : "Ha ocurrido un error.",
                showConfirmButton: false,
                timer: 2500
            })
        }
        return valid
    }
    

    // //SUBMIT DEL LOGIN
    submit = async (event) => {
        event.preventDefault()
        const self = this
        if (this.state.isLogin) {
            const payload= {
                "email": this.state.email,
                "password": this.state.password,
            }
            if (this.validateReq(payload)) {
                await postmanejado('/api/auth/login', payload)
                .then(async result => {
                    if (result.status === 401) {
                        throw {status: result.status};
                    }
                    await localStorage.setItem('token', result.access_token)
                    await localStorage.setItem('expires_at', result.expires_at)
                    await self.props.consultarUsuario()
                    return result
                })
                .catch(async error => {
                    const data = await error
                    if(data.status === 401) {
                        MySwal.fire({
                            icon: 'error',
                            text: 'Usuario o contraseña incorrecta',
                        })
                    } else {
                        if(data.message) {
                            MySwal.fire({
                            icon: 'error',
                                text: data.message,
                            })
                        } else {
                            MySwal.fire({
                            icon: 'error',
                                text: 'Ha ocurrido un error, por favor intente nuevamente',
                            })
                        }
                    }
                })
            } 
        }
    }

    //ESCUCHA LA TECLA "ENTER" PARA HACER EL LOGIN
    enterKeySubmit = (e) => {
        if (e.which === 13) {
            this.submit(e)
        }
    }

    ingresarConHash = async (hash) => {
        const data = await this.props.consultarInvitado(hash)
        if(data.length > 0) {
            const user = await data.find(us=>!us.parent_id)
            if (typeof(data) === "object") {
                await this.props.setUser(user)
            }
            if(user && user.event && user.event[0] && user.event[0].custom_background) {
                this.props.setBackground(user.event[0].custom_background)
            }
            localStorage.setItem('user', JSON.stringify(user))
            this.props.setLogin([])
        }
    }

    verificarHashOToken = async () => {
        const { params } = this.state
        const hash = params.get("hash")
        if(hash) {
            this.props.setLoading(true)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            await this.ingresarConHash(hash)
        }
    }

    async componentDidMount() {
        await this.verificarHashOToken()
        if(!this.state.eventListener) {
            this.setState({
                eventListener: true
            })
            document.addEventListener("keydown", this.enterKeySubmit)
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.enterKeySubmit)
    }

    cambiarTipoInput = () => {
        let input = document.getElementById('password')
        input.type = input.type === "password" ? "text" : "password"
    }

    render() {
        const { isLogin } = this.state

        return (
                <React.Fragment>
                    <div className="login-title" >
                        <img className="brand-logo" src={brandLogo} alt="brand-logo" ></img>
                    </div>
                    {/* BLOQUE DE LOGIN */}
                    <div className="theme-input col-9 d-flex">
                        <ReactSVG src={usuarioSVG} className="px-2 svg" />
                        <input type="text" id="username" name="username" className='theme-input-box' value={this.state.email} placeholder={"E-mail"} autoComplete='on' onChange={(e) => this.setState({ email: e.target.value })} />
                    </div>

                    <div key={2} className="theme-input col-9 d-flex align-items-center">
                        <ReactSVG src={candadoSVG} className="px-2 svg" />
                        <input id="password" name="password" type="password" className='theme-input-box' value={this.state.password} placeholder="Contraseña" autoComplete='on' onChange = {(e) => this.setState({ password: e.target.value })} />
                        <ReactSVG src={ojopassword} className="px-2 svg" style={{cursor: "pointer"}} onClick={this.cambiarTipoInput} />
                    </div>

                    <div className="col-9 div-button" >
                        <button type="submit" className="theme-button f-14 fw-500" onClick={this.submit}>
                        {isLogin? 'COMENZAR' : 'Enviar'}
                        </button>
                    </div>
                    <div className="d-flex justify-content-center f-13 remember mt-3">
                        {isLogin ? 
                        <React.Fragment>
                            <div >
                            ¿Olvidaste tu contraseña? 
                            </div>
                            <Link to={`${process.env.PUBLIC_URL}/Recuperar`}>
                                <div className="settings-celeste" style={{ display: 'inline-block', marginLeft: "5px"}}>
                                    Recuperar
                                </div>
                            </Link>
                        </React.Fragment>
                        : null
                        }
                    </div>

                    {/* // BLOQUE DE LOGIN // */}
                </React.Fragment>
        );
    }
}