import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {version} from '../package.json'

// import { version } from '../../package.json'
import Recuperar from './recuperar'
import Restablecer from './restablecer'
import Login from './login'


export default class LoginScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            registroactivo: false,
            invitados: [],
        }
    }

    //AJUSTA EL PADDING SEGÚN SI LA PARTE DE REGISTRO ESTÁ ACTIVA O NO

    activarRegistro = (boolean) => {
      this.setState({
        registroactivo: boolean,
      })
    }

    render() {
        return (
                <div id='login' >
                    <div className={"login-form col-md-8 col-lg-4 " + (this.state.registroactivo ? "registro" : "")}>
                        {/* SEGÚN LA RUTA SE RENDERIZA LA PARTE CORRESPONDIENTE */}
                        <Router>
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/Restablecer`} render={() => <Restablecer parentContext={this} />}/>
                                <Route path={`${process.env.PUBLIC_URL}/Restablecer/:hash`} render={() => <Restablecer parentContext={this} />}/>
                                <Route path={`${process.env.PUBLIC_URL}/Recuperar`} render={() => <Recuperar parentContext={this} />}/>
                                <Route path={`${process.env.PUBLIC_URL}/`} render={() => <Login parentContext={this} loading={this.props.loading} user={this.props.user} setUser={this.props.setUser} setLogin={this.props.setLogin} setLoading={this.props.setLoading} setEvent={this.props.setEvent} setError={this.props.setError} consultarAsociados={this.consultarAsociados} consultarConHash={this.consultarConHash} consultarInvitado={this.props.consultarInvitado} consultarUsuario={this.props.consultarUsuario} setBackground={this.props.setBackground} />}/>
                            </Switch>
                        </Router>
                    </div>
                    <div style={{marginTop: '30px'}}>
                        {/* <img className="brand-logo mb-5" src={} alt="brand-logo" ></img> */}
                    </div>
                        {/* INDICA LA VERSIÓN ACTUAL */}
                    <div className="version">v{version}</div>
                </div>
        );
    }
}