import urlServer from './server'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export const postmanejado = async (ruta, data, auth, pdf, urlExterna) => {
    let url = urlExterna || urlServer;
    let form = new FormData()
    const keys = Object.keys(data)
    for(let key of keys) {
        form.append(key, data[key])
    }
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(auth) {
        myHeaders.append("Authorization", "Bearer " + localStorage.token);
    }
    let response = await fetch(`${url + ruta}`, {
        method: 'POST',
        body: form,
        headers: myHeaders,
    })
    .then(async response => {
        let esLogin = data.email && data.password
        if((parseInt((await response).status) === 401) && !esLogin) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            await MySwal.fire({
                icon: 'error',
                title: "Su sesión ha expirado.",
                showConfirmButton: true,
            })
            .then(res => {
                window.location.reload();
            })
            return
        }
        if((parseInt((await response).status) === 200)) {
            if(pdf) {
                return response
            } else {
                return response.json()
            }
        } else {
            const err = new Error('Error en el servidor')
            throw {err, data: { message: 'Error en el servidor'}, status: response.status}
        }

    })
    .then(async response => {
        return await response
    })
    .catch(err => {
        MySwal.fire({
            icon: 'error',
            title: (err.data && err.data.message) || "Error en el servidor",
            showConfirmButton: true,
        })
        return err
    })
    return await response
}