import React, { Component } from "react";
import { postmanejado } from './fetchmanejado';

import urlServer from './server'

export default class ManejarModulos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalModulos: [],
            cambiandoEstado: false,
        };
    }

    getModulos = async () => {
        const response = await fetch(`${urlServer}/api/getModulos`);
        const modulos = await response.json() || [];
        modulos.sort((a, b) => b.gratuito - a.gratuito);
        this.setState({
            totalModulos: modulos,
        })
    }

    cambiarEstadoModulo = async (modulo, event, e) => {
        await this.setState({
            cambiandoEstado: true
        })
        
        let data

        if(e.target.checked) {
            const relacion = {
                event_id: event.id,
                modulo_id: modulo.id,
            }
            data = {
                axn: "create",
                fields: JSON.stringify(relacion),
                entity: "ModulosEvent"
            }
        } else {
            modulo = event.modulos.find(mod => mod.id === modulo.id);
            data = {
                id: modulo.pivot.id,
                axn: "delete",
                entity: "ModulosEvent"
            }
        }

        

        await postmanejado(`/api/auth/abmRowFromModel`, data, true)
        .then(async res => {
            await this.props.consultarUsuario();
            await this.setState({
                user: JSON.parse(localStorage.user)
            })
        })

        await this.setState({
            cambiandoEstado: false
        })
    }

    componentDidMount() {
        this.getModulos();
    }

    render() {
        const { totalModulos, cambiandoEstado } = this.state;
        const { user, eventSelected } = this.props

        return (
                <div>
                    {totalModulos.map((modulo, index) => {
                        const event = user.event[eventSelected]
                        const { modulos } = event;
                        let checked = modulo.gratuito || modulos.find(mod => mod.id === modulo.id);
                        return  (<div className="d-flex mb-5" key={index}>
                                    <div className="col-10 text-start f-16">
                                        {modulo.nombre.toUpperCase()}
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="checkbox"
                                            id={"switch" + index}
                                            onChange={(e) => this.cambiarEstadoModulo(modulo, event, e)}
                                            className="switch-input"
                                            defaultChecked={checked}
                                            disabled={modulo.gratuito || cambiandoEstado}
                                        />
                                        <label htmlFor={"switch" + index} className="switch-label">
                                            <span className="switch-button"></span>
                                        </label>
                                    </div>
                                </div>)
                    })}
                </div>
        );
    }
}
