import React from "react";
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { postmanejado } from './fetchmanejado';

const MySwal = withReactContent(Swal)



const enviarData = async (api, data) => {
    const response = await postmanejado(api, data, true);
    return response;
}
      
const AgregarRespuesta = () => {
    const respuesta4 = document.getElementById('respuesta4-container');
    const respuesta5 = document.getElementById('respuesta5-container');
    const agregarRta = document.getElementById('agregar-respuestas');
    if (respuesta4.classList.contains('d-none')) {
        respuesta4.classList.remove('d-none');
        respuesta4.classList.add('d-block');
    } else if (respuesta5.classList.contains('d-none')) {
        agregarRta.classList.add('d-none');
        respuesta5.classList.remove('d-none');
        respuesta5.classList.add('d-block');
    }
}

const selectF = () => {
    // MODAL PARA AGREGAR EVENTO
    
    return(
            <div className="w-100 mb-3 text-start" id="preguntas-para-agregar">
                <div className="w-100 mb-3" >
                    <input id="pregunta" type="text" className="form-control" placeholder="Pregunta"/> 
                </div>

                <p className="mb-3">No se guardarán las respuestas que queden en blanco.</p>

                <div className="w-100 mb-3" >
                    <label for="correcta" className="col-11"><input id="respuesta1" name="respuesta" type="text" className="form-control" placeholder="Respuesta 1" /> </label>
                    <input type="radio" id="correcta1" name="correcta" value="1" className="col-1" />
                </div>

                <div className="w-100 mb-3" >
                    <label for="correcta" className="col-11"><input id="respuesta2" name="respuesta" type="text" className="form-control" placeholder="Respuesta 2" /></label>
                    <input type="radio" id="correcta2" name="correcta" value="1" className="col-1"/>
                </div>

                <div className="w-100 mb-3" >
                    <label for="correcta" className="col-11"><input id="respuesta3" name="respuesta" type="text" className="form-control" placeholder="Respuesta 3" /></label>
                    <input type="radio" id="correcta3" name="correcta" value="1" className="col-1"/>
                </div>

                <div id="respuesta4-container" className="w-100 mb-3 d-none" >
                    <label for="correcta" className="col-11"><input id="respuesta4" name="respuesta" type="text" className="form-control" placeholder="Respuesta 4" /></label>
                    <input type="radio" id="correcta4" name="correcta" value="1" className="col-1"/>
                </div>

                <div id="respuesta5-container" className="w-100 mb-3 d-none" >
                    <label for="correcta" className="col-11"><input id="respuesta5" name="respuesta" type="text" className="form-control" placeholder="Respuesta 5" /></label>
                    <input type="radio" id="correcta5" name="correcta" value="1" className="col-1"/>
                </div>
                <div id="agregar-respuestas" className="btn btn-secondary float-end" onClick={() => AgregarRespuesta()}>
                    +
                </div>
            </div>
    )
}

export default function AgregarPregunta (user, eventSelected, init) {
    const titulo = "Agregar pregunta"

    // SWEET ALERT CON ACCION DE AGREGAR EVENTO

    MySwal.fire({
        title: titulo,
        confirmButtonText: "Agregar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        customClass: {
            "title": "agregar-modal",
            "popup": "agregar-modal",
        },
        html: `
        <div id="agregarCivil" class="w-100">
        </div>
        `,
        didRender: () => {
            ReactDOM.render(React.createElement(selectF, {}), document.getElementById("agregarCivil"));
        },
        preConfirm: async () => {
            MySwal.resetValidationMessage();
            let correctas = Array.from(document.getElementsByName("correcta")).filter(rta => rta.checked);
            let pregunta = document.getElementById("pregunta");
            if (pregunta.value === "") {
                MySwal.showValidationMessage(
                    `Debe agregar texto en el campo de pregunta`
                );
                return;
            }
            
            let respuestas = Array.from(document.getElementsByName("respuesta")).filter(rta => rta.value !== "" && rta.className.indexOf('d-none') === -1);
            if (respuestas.length < 2) {
                MySwal.showValidationMessage(
                    `Debe agregar texto en al menos dos campos de respuesta`
                );
                return;
            }
            let error = true;
            for (let i = 0; i < correctas.length; i++) {
                let id = correctas[i].id;
                let num = id[id.length - 1];
                let respuesta = document.getElementById(`respuesta${num}`);
                if (respuesta.value !== "" && respuesta.className.indexOf('d-none') === -1) {
                    error = false;
                    break;
                }
            }
            if (error) {
                MySwal.showValidationMessage(
                    `Debe seleccionar una respuesta que no esté en blanco como opción correcta`
                );
                return;
            }
            MySwal.showLoading()
            const inputPregunta = document.getElementById("pregunta")
            if(!inputPregunta) {
                MySwal.showValidationMessage(
                    `Ha ocurrido un error`
                );
            }
            let nuevaPregunta = {
                event_id: user.event[eventSelected].id,
                pregunta: inputPregunta.value
            }
            await enviarData("/api/auth/insertNuevaPregunta", nuevaPregunta)
            .then(async (res) => {
                const { pregunta } = res;
                if(pregunta && pregunta.id) {
                    let respuestas = Array.from(document.getElementsByName("respuesta"))
                    for (let i = 0; i < respuestas.length; i++) {
                        let respuesta = document.getElementById("respuesta"+(i+1));
                        let correcta = document.getElementById("correcta"+(i+1));
                        if(respuesta && respuesta.value && correcta) {
                            respuesta = respuesta.value;
                            correcta = correcta.checked ? 1 : 0;
                            let nuevaRespuesta = {
                                pregunta_id: pregunta.id,
                                respuesta,
                                correcta
                            };
                            let response = await enviarData("/api/auth/insertNuevaRespuesta", nuevaRespuesta);
                            console.log(response);
                        }
                    }
                }
            })
            init();
        }
      })
}