import React from "react";
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { postmanejado } from './fetchmanejado';

const MySwal = withReactContent(Swal)

const enviarData = async (api, data) => {
    const response = await postmanejado(api, data, true);
    return response;
}
      
const AgregarRespuesta = () => {
    const respuesta3 = document.getElementById('respuesta3-container');
    const respuesta4 = document.getElementById('respuesta4-container');
    const respuesta5 = document.getElementById('respuesta5-container');
    const agregarRta = document.getElementById('agregar-respuestas');

    if (respuesta3 && respuesta3.classList.contains('d-none')) {
        respuesta3.classList.remove('d-none');
        respuesta3.classList.add('d-block');
    } else if (respuesta4 && respuesta4.classList.contains('d-none')) {
        respuesta4.classList.remove('d-none');
        respuesta4.classList.add('d-block');
    } else if (respuesta5 && respuesta5.classList.contains('d-none')) {
        agregarRta.classList.add('d-none');
        respuesta5.classList.remove('d-none');
        respuesta5.classList.add('d-block');
    }
}

const selectF = (pregunta) => {
    const inputs = new Array(5).fill(null);
    console.log(pregunta);
    // MODAL PARA AGREGAR EVENTO
    
    return(
            <div className="w-100 mb-3 text-start" id="preguntas-para-agregar">
                <div className="w-100 mb-3" >
                    <input id={"pregunta"+pregunta.id} type="text" className="form-control" placeholder="Pregunta" defaultValue={pregunta.pregunta}/> 
                </div>

                <p className="mb-3">No se guardarán las respuestas que queden en blanco.</p>

                {inputs.map((_, index) => {
                    return (
                        <div key={index} id={!pregunta.respuestas[index] ? `respuesta${index+1}-container` : undefined} className={"w-100 mb-3 " + (!pregunta.respuestas[index] ? "d-none" : undefined)} >
                            <label htmlFor="correcta" className="col-11"><input id={pregunta.respuestas[index] ? ("id-respuesta" + pregunta.respuestas[index].id) : ("respuesta" + index)} name="respuesta" type="text" className="form-control" placeholder={"Respuesta "+(index+1)} defaultValue={pregunta.respuestas[index]?.respuesta || undefined}/> </label>
                            <input type="radio" id={pregunta.respuestas[index] ? ("id-correcta" + pregunta.respuestas[index].id) : ("correcta" + index)} name="correcta" value="1" className="col-1" defaultChecked={pregunta.respuestas[index]?.correcta || undefined} />
                        </div>
                    );
                })}

                <div id="agregar-respuestas" className="btn btn-secondary float-end" onClick={() => AgregarRespuesta()}>
                    +
                </div>
                <p>Los votos se mantienen, no se resetean.</p>
            </div>
    )
}

export default function EditarPregunta (user, eventSelected, init, pregunta) {
    const titulo = "Editar pregunta"

    // SWEET ALERT CON ACCION DE AGREGAR EVENTO

    MySwal.fire({
        title: titulo,
        confirmButtonText: "Guardar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        customClass: {
            "title": "agregar-modal",
            "popup": "agregar-modal",
        },
        html: `
        <div id="editarPregunta" class="w-100">
        </div>
        `,
        didRender: () => {
            ReactDOM.render(React.createElement(() => selectF(pregunta), {}), document.getElementById("editarPregunta"));
        },
        preConfirm: async () => {
            MySwal.resetValidationMessage();
            let correctas = Array.from(document.getElementsByName("correcta")).filter(rta => rta.checked);
            let inputPregunta = document.getElementById("pregunta"+pregunta.id);
            if(!inputPregunta) {
                MySwal.showValidationMessage(
                    `Ha ocurrido un error`
                );
            }
            if (inputPregunta.value === "") {
                MySwal.showValidationMessage(
                    `Debe agregar texto en el campo de pregunta`
                );
                return;
            }
            
            let inputRespuestas = Array.from(document.getElementsByName("respuesta")).filter(rta => rta.value !== "" && rta.className.indexOf('d-none') === -1);
            if (inputRespuestas.length < 2) {
                MySwal.showValidationMessage(
                    `Debe agregar texto en al menos dos campos de respuesta`
                );
                return;
            }
            let error = true;
            for (let i = 0; i < correctas.length; i++) {
                let id = correctas[i].id.split("correcta")[1];
                let tieneId = ""
                if(correctas[i].id.indexOf("id") !== -1) {
                    tieneId = "id-"
                }
                let respuesta = document.getElementById(`${tieneId}respuesta${id}`);
                if (respuesta.value !== "" && respuesta.className.indexOf('d-none') === -1) {
                    error = false;
                    break;
                }
            }
            if (error) {
                MySwal.showValidationMessage(
                    `Debe seleccionar una respuesta que no esté en blanco como opción correcta`
                );
                return;
            }
            MySwal.showLoading()
            let { respuestas } = pregunta;
            inputRespuestas = Array.from(document.getElementsByName("respuesta")).filter(rta => rta.id.indexOf("id-")  === -1 && rta.value !== "" && rta.className.indexOf('d-none') === -1);
            let nuevasRespuestas = respuestas.map(respuesta => {
                let inputRta = document.getElementById(`id-respuesta${respuesta.id}`);
                let inputCorrecta = document.getElementById(`id-correcta${respuesta.id}`);
                respuesta = {
                    ...respuesta,
                    respuesta: inputRta.value,
                    correcta: inputCorrecta.checked ? 1 : 0
                }
                return respuesta
            })
            nuevasRespuestas = [
                ...nuevasRespuestas,
                ...inputRespuestas.map(respuesta => {
                    let id = respuesta.id.split("respuesta")[1];
                    let correcta = document.getElementById("correcta"+(id));
                    let nuevaRespuesta
                    if(respuesta && respuesta.value && correcta) {
                        respuesta = respuesta.value;
                        correcta = correcta.checked ? 1 : 0;
                        nuevaRespuesta = {
                            pregunta_id: pregunta.id,
                            respuesta,
                            correcta
                        };
                    }
                    return nuevaRespuesta;
                })
            ]
            pregunta = {
                ...pregunta,
                pregunta: inputPregunta.value,
            }
            
            pregunta.respuestas = JSON.stringify(nuevasRespuestas);
            await enviarData("/api/auth/editarPregunta", pregunta)
            .then(async (res) => {
                console.log(res)
            })
            init();
        }
      })
}