import React from 'react';

import { postmanejado } from './fetchmanejado';

import Swal from 'sweetalert2'

const MAX_IMAGES = 10;

class ImageUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            uploadedImages: []
        };
    }

    handleImageChange = (e) => {
        const files = e.target.files;
        this.addImages(files);
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        this.addImages(files);
    };

    addImages = (files) => {
        for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!file.type.match('image.*')) {
            Swal.fire({
                icon: 'error',
                text: 'Solo se permiten imágenes',
            })
            continue;
        }
        if (file.size > 5 * 1024 * 1024) {
            Swal.fire({
                icon: 'error',
                text: 'El tamaño máximo de la imagen es de 5MB',
            });
            continue;
        }
        if (
            !this.state.images.some(
            (image) => image.name === file.name && image.size === file.size
            )
        ) {
            this.setState((prevState) => ({
            images: [...prevState.images, file]
            }));
        }
        }
    };

    removeImage = (index) => {
        this.setState((prevState) => ({
            images: prevState.images.filter((_, i) => i !== index)
        }));
    };

    getError = (err) => {
        console.log(err);
        return {error: 1};
    }

    async handleUpload(event, invitado_id, hash, event_id) {
        const files = this.state.images;
        let error = 0;
        
        Swal.fire({
            title: 'Subiendo archivo/s',
            // html: 'I will close in <b></b> milliseconds.',
            didOpen: async () => {
                Swal.showLoading()
                for (const file of files) {
        
                    const data = {
                        invitado_id,
                        hash,
                        event_id,
                        file
                    }
        
                    const response = await postmanejado(`/api/uploadFotoFile`, data)
                    .then((res) => {
                        return res;
                    })
                    .catch(this.getError);
                    if(response && response.error) {
                        error += 1;
                    }
                }
                
                if(!error) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Archivo/s subidos con éxito',
                        showConfirmButton: false,
                        timer: 3500
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Alguno de los archivos tuvo un error',
                        showConfirmButton: false,
                        timer: 3500
                    });
                }
                // Obtener una referencia al elemento input
                const input = document.querySelector('input[type="file"]');
        
                // Limpiar los archivos seleccionados
                input.value = '';
                this.setState({
                    images: []
                })

                this.init(event_id, invitado_id, hash);
                Swal.clickConfirm()
            },
        })
    }

    removeUploadedImage = async(event_id, invitado_id, hash, foto_id) => {
        let data = { 
            event_id,
            invitado_id,
            hash,
            foto_id
        };
        const response = await postmanejado("/api/eliminarFotoInvitado", data, true);
        return response;
    }

    seExcedioFechaLimite = () => {
        const { user, eventSelected } = this.props
        let fechaLimiteExcedida = false
        if(new Date(user && user.event[eventSelected] && user.event[eventSelected].fechaCierre) > new Date()) {
        } else {
            fechaLimiteExcedida = true
        }
        return fechaLimiteExcedida
    }

    getFotos = async (event_id, invitado_id, hash) => {
        let data = { event_id, invitado_id, hash };
        const { fotos} = await postmanejado("/api/listFotosInvitado", data, true);
        return fotos;
    }

    init = async (event_id, invitado_id, hash) => {
        const uploadedImages = await this.getFotos(event_id, invitado_id, hash);
        this.setState({
            uploadedImages
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.eventSelected !== null && this.props.eventSelected !== undefined && this.props.user.event && this.props.user.event[this.props.eventSelected] &&
        (this.props.user !== prevProps.user || this.props.eventSelected !== prevProps.eventSelected)) {
            const event_id = this.props.user.event[this.props.eventSelected].id;
            this.init(event_id, this.props.user.id, this.props.user.hash);
        }
    }
    
    componentDidMount() {

        if (this.props.user && this.props.eventSelected !== null && this.props.eventSelected !== undefined && this.props.user.event && this.props.user.event[this.props.eventSelected]) {
            const event_id = this.props.user.event[this.props.eventSelected].id;
            this.init(event_id, this.props.user.id, this.props.user.hash);
        }
    }

    render() {
        const totalImages = this.state.images.length + this.state.uploadedImages.length;
        const { user, eventSelected } = this.props;

        let fechaLimiteExcedida = this.seExcedioFechaLimite();
        
        return (
        <div>
            <p>
                Imágenes: {totalImages}/{MAX_IMAGES}
            </p>
            {!fechaLimiteExcedida && 
            <>
                <div
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                style={{
                    border: '2px dashed #ccc',
                    padding: '20px',
                    opacity: totalImages >= MAX_IMAGES ? 0.5 : 1
                }}
                >
                {totalImages >= MAX_IMAGES
                    ? 'Se alcanzó el límite de imágenes'
                    : 'Arrastra y suelta tus imágenes aquí'}
                </div>
                {totalImages < MAX_IMAGES && (
                <input
                    type="file"
                    className="my-4"
                    multiple
                    onChange={this.handleImageChange}
                    accept="image/*"
                />
                )}
            </>
            }
            {this.state.images.length > 0 && (
            <>
                <div className="row">
                {this.state.images.map((image, index) => (
                    <div key={index} className="col-6 col-md-2">
                    <div style={{ aspectRatio: '1', display: 'flex' }}>
                        <img
                        src={URL.createObjectURL(image)}
                        alt={image.name}
                        className="img-fluid m-auto"
                        />
                    </div>
                    <div className="text-center">
                        <button
                        className="btn btn-danger btn-sm"
                        onClick={() => this.removeImage(index)}
                        >
                        Descartar
                        </button>
                    </div>
                    </div>
                ))}
                </div>
                <div className="mt-3">
                <button className="btn btn-primary" onClick={(e) => this.handleUpload(e, user.id, user.hash, user.event[eventSelected].id)}>Enviar</button>
                </div>
            </>
            )}
            {fechaLimiteExcedida && 
                <h6>
                    Finalizó el período de envío de fotos.
                </h6>
            }
            {this.state.uploadedImages.length > 0 && (
            <>
                <h3>Imágenes subidas:</h3>
                <div className="row">
                {this.state.uploadedImages.map((image, index) => (
                    <div key={index} className="col-6 col-md-2">
                    <div style={{ aspectRatio: '1', display: 'flex' }}>
                        <img
                        src={'https://drive.google.com/thumbnail?sz=w500&id=' + image.file_id}
                        alt={image.file}
                        className="img-fluid m-auto"
                        />
                    </div>
                    <div className="text-center">
                        <button
                        className="btn btn-danger btn-sm"
                        onClick={() => 
                            
                            Swal.fire({
                                title: 'Eliminando ítem.',
                                text: '¿Desea eliminar este ítem?',
                                showCancelButton: true,
                                confirmButtonText: 'Continuar',
                                cancelButtonText: 'Cancelar',
                                showLoaderOnConfirm: true,
                                allowOutsideClick: () => !Swal.isLoading()
                            }).then((result) => {      
                                if (result.isConfirmed) {
                                    Swal.fire({
                                            title: 'Eliminando ítem',
                                            // html: 'I will close in <b></b> milliseconds.',
                                            didOpen: async () => {
                                                Swal.showLoading()
                                                await this.removeUploadedImage(user.event[eventSelected].id, user.id, user.hash, image.id)

                                                Swal.clickConfirm()
                                            },
                                    }).then((result) => {
                                        this.init(user.event[eventSelected].id, user.id, user.hash);
                                    })

                                }
                            })
                        }
                        >
                            Eliminar
                        </button>
                    </div>
                    </div>
                ))}
                </div>
            </>
            )}
        </div>
        );
    }
}

export default ImageUploader;
