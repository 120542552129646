import React, { Component } from "react";
import { postmanejado } from './fetchmanejado';

export default class MusicaInvitado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracks: [],
            selected: [],
            user: this.props.user ? this.props.user : JSON.parse(localStorage.user),
            eventSelected: this.props.eventSelected ? this.props.eventSelected : 0,
            selectedReady: true,
            busqueda: "",
            tabtracks: true,
            tabselected: false, 
            loadeds: 0,
            loading: false
        };
    }

    getMusica = async (busqueda) => {
        let data = { query: busqueda };
        const response = await postmanejado("/api/getTrack", data);
        return response;
    };

    buscarSpotify = async (busqueda) => {
        this.setState({ tracks: [], loadeds: 0, loading: true });
        const { tracks } = await this.getMusica(busqueda);
        this.setState({ tracks });
    };

    seleccionarItem = async (invitado, cancion, eventSelected) => {
        let cancionAVotar = {
            spotify_id: cancion.id,
            nombre: cancion.artists[0].name + " - " + cancion.name
        };
        const response = await this.submitCancion(invitado, cancionAVotar);
        if(response.response === true) {
            await this.init(invitado, eventSelected);
        }
        return response;
    };

    enviarData = async (endpoint, data) => {
        const response = await postmanejado(endpoint, data);
        return response;
    }

    submitCancion = async (invitado, cancion) => {
        const response = await this.enviarData("/api/insertVotoCancion", {invitado_id: invitado.id, spotify_id: cancion.spotify_id, nombre_cancion: cancion.nombre});
        return response;
    };

    deleteVoto = async (invitado, cancion) => {
        const response = await this.enviarData("/api/deleteVotoCancion", {invitado_id: invitado.id, spotify_id: cancion.spotify_id});
        return response;
    }

    getVotos = async (id, event_id) => {
        const data = {
            invitado_id: id,
            event_id
        }
        const response = await this.enviarData("/api/getVotosCancion", data)
        return response;
    };

    init = async (user, eventSelected) => {
        let selected = await this.getVotos(user.id, user.event[eventSelected].id);
        await this.setState({
            selected
        })
    }

    componentDidMount() {
        const { user, eventSelected } = this.state
        this.init(user, eventSelected)
    }

    mostrarSelectos = (selected, user, eventSelected) => {
        return (
                <div className="bloque-temas-elegidos">
                    <h5>
                        Temas elegidos
                    </h5>
                    <h6 className="text-start mt-5 mb-4">
                        {this.seExcedioFechaLimite() ? "Finalizó el período de votación de canciones" : "Puede elegir un máximo de 3 canciones."}
                    </h6>
                    <div className="temas-elegidos">
                        {selected.map((track, index) => {
                            return (
                                <div key={index} className="item-grid d-flex flex-wrap justify-content-center mb-4">
                                    <iframe style={{ "borderRadius": "12px" }} src={"https://open.spotify.com/embed/track/" + track.spotify_id + "?utm_source=generator"} 
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"
                                        width="100%" height="85%" frameBorder="0" allowFullScreen=""
                                        title={"id" + track.spotify_id}
                                    />
                                    <div className="position-relative mt-3">
                                        <input type="checkbox" name={"check" + index} checked={track.selected} className="col-md-2 col-4 mx-auto position-absolute h-100 w-100" style={{opacity: "0", cursor: "pointer"}} onChange={async (e) => {
                                            const input = document.getElementById("S" + track.spotify_id);
                                            if(input) {
                                                input.click();
                                            }
                                            await this.deleteVoto(user, track)
                                            this.init(user, eventSelected)
                                        }}/>
                                        <button className="btn btn-danger"> Eliminar</button>
                                    </div>
                                </div>
                            );
                            })}
                    </div>
                </div>)
    }

    mostrarBusqueda = (tracks, user, eventSelected, selected, loading) => {
        return (
            <>
                <p className="text-start">
                    <strong>
                        Ayudanos a elegir los temas importantes que no pueden faltar
                    </strong>
                </p>
                <form action="">
                    <input className="form-control" value={this.state.busqueda} 
                        placeholder="Escriba el nombre de una canción" 
                        onChange={(e) => this.setState({ busqueda: e.target.value })}
                    />
                    <button type="submit" className="button-invitados mt-4" onClick={async (e) => {
                            e.preventDefault();
                            this.buscarSpotify(this.state.busqueda);
                        }}
                    >
                        Buscar
                    </button>
                </form>
                <div className={"mt-5 lista-temas " + (loading || (selected.length >= 3) ? "position-fixed" : "")} style={(loading || (selected.length >= 3) ? {opacity: "0"} : {})}>
                    {tracks.items && tracks.items.sort((a, b) => b.popularity - a.popularity).map((track, index) => {
                        return (
                            <div key={index} className="item-grid d-flex flex-wrap align-items-center iframe-spotify justify-content-center justify-content-md-between mb-5">
                                <iframe className="col-md-10 col-12 mb-4 mb-md-0" src={"https://open.spotify.com/embed/track/" + track.id + "?utm_source=generator"} 
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"
                                    title={"id" + track.id}
                                    width="100%" height="80px" frameBorder="0" onLoad={() => {
                                        let { loadeds, loading } = this.state;
                                        loadeds++;
                                        if(loadeds===10 || loadeds === tracks.items.length){
                                            loading = false;
                                        }
                                        this.setState({
                                            loadeds,
                                            loading
                                        })
                                    }}
                                />
                                <div className="div-agregar-musica" style={{height: 'fit-content', width: 'fit-content'}}>
                                    <input type="checkbox" id={"S" + track.id} className="check-musica col-md-2 col-4 mx-auto" onChange={(e) => this.seleccionarItem(user, track, eventSelected)}/>
                                    <div className="button-invitados">
                                        Elegir
                                    </div>
                                </div>
                            </div>
                        );
                        })}
                </div>
            </>
            )
    }

    seExcedioFechaLimite = () => {
        const { user, eventSelected } = this.props
        let fechaLimiteExcedida = false
        if(new Date(user && user.event[eventSelected] && user.event[eventSelected].fechaCierre) > new Date()) {
        } else {
            fechaLimiteExcedida = true
        }
        return fechaLimiteExcedida
    }

    render() {
        let { tracks, selected, user, loading, eventSelected, tabselected, tabtracks } = this.state;

        let fechaLimiteExcedida = this.seExcedioFechaLimite();

        return (
        <div>
            {tracks && (
            <>
                {!fechaLimiteExcedida &&
                <div className="tabs my-3 mx-auto">
                    <div className="d-flex justify-content-around p-1 f-14">
                        <div className={"tab d-flex align-items-center justify-content-center px-4 px-md-5" + (tabtracks? " active" : "")} onClick={() => {
                            this.setState({
                                tabselected: false,
                                tabtracks: true,
                            })
                        }}>
                            ELEGIR TEMAS
                        </div>
                        <div className={"tab d-flex align-items-center justify-content-center px-4 px-md-5" + (tabselected? " active" : "")} onClick={() => {
                            this.setState({
                                tabselected: true,
                                tabtracks: false,
                            })
                        }}>
                            TEMAS ELEGIDOS
                        </div>
                    </div>
                </div>}
                {!fechaLimiteExcedida && tabtracks && this.mostrarBusqueda(tracks, user, eventSelected, selected, loading)}
                {(fechaLimiteExcedida || tabselected) && this.mostrarSelectos(selected, user, eventSelected)}
            </>
            )}
        </div>
        );
    }
}
