import * as React from 'react';
import './App.scss';
import LoginScreen from './loginscreen'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import urlServer from './server'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Spinner from './spinner.js'
import Landing from './landing'
const MySwal = withReactContent(Swal)


export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        background: "background1",
        loading: false,
        user: {
        },
        eventoIniciado: false,
        eventSelected: 0,
        errorEvent: false,
        loginPage:[]
        }
    }

    history = createBrowserHistory()

    setLoading = (loading) => {
        this.setState({
        loading
        })
    }

    //UNA VEZ LOGEADO, SETEA LO QUE SE RECIBE COMO "LANDING PAGE"
    setLanding = (landing) => {
        this.setState({
        landing
        })
    }

    //SETEA PÁGINA DE LOGIN, O LA ELIMINA EN CASO DE LOGIN EXITOSO
    setLogin = (login) => {
        this.setState({
        loginPage: login
        })
    }

    setBackground = (background) => {
        this.setState({
        background
        })
    }

    //REGRESA LA PÁGINA DE LOGIN PARA HACER EL LOGOUT
    loggingOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        this.setBackground("background1")
        this.setState({
        user: {
        }
        });
    }

    consultarUsuario = async (eventSelected) => {
        const apiBaseUrl = urlServer + `/api/auth/getuser`
        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.token);
        let response = await fetch(apiBaseUrl, {
            method: 'GET',
            headers: myHeaders,
        })
        .then(async response => {
            let data = {}
            if(parseInt(response.status) === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                MySwal.fire({
                    icon: 'error',
                    title: "Su sesión ha expirado.",
                    showConfirmButton: true,
                })
                .then(res => {
                    window.location.reload();
                })
                return
            } else if(parseInt(response.status) === 200) {
                data = await response.json()
                if (typeof(data) === "object") {
                    if(data.event && data.event.length > 0) {
                        for (let i = 0; i < data.event.length; i++) {
                            const evento = data.event[i];
                            if(evento && !evento.custom_background) {
                                if(evento.type_event === "F15" || evento.type_event === "BAT") {
                                evento.custom_background = "background4"
                                evento.custom_color = "settings-amarillo"
                                } else {
                                evento.custom_background = "background1"
                                evento.custom_color = "settings-naranja"
                                }
                            }
                        }
                    }
                    if(!this.state.eventoIniciado) {
                        eventSelected = data?.event?.findIndex(event => event.id === this.state.eventInicial);
                        if(eventSelected > -1) {
                            await this.setState({
                                eventSelected
                            })
                        }
                        this.setState({
                            eventoIniciado: true
                        })
                    }
                    await this.setState({
                        user: data
                    })

                    if(eventSelected || eventSelected === 0) {
                        this.setBackground(data.event[eventSelected || 0].custom_background)
                    }

                    localStorage.setItem('user', JSON.stringify(data))
                }
            } else {
                console.log(response.status)
            }
            return {response: true, user: data}
        })
        .catch(res => {
            console.log(res)
            return {response: false}
        })
        return response
    }
  

    consultarConHash = async (hash) => {
        const apiBaseUrl = urlServer + `/api/getInvitadoHash?featHash=${hash}`
        const response = await fetch(apiBaseUrl)
        const data = await response.json()
        return data
    }

    consultarAsociados = async(id, hash) => {
        const apiBaseUrl = urlServer + `/api/getInvitado?invitadoId=${id}&hash=${hash}`
        const response = await fetch(apiBaseUrl)
        const data = await response.json()
        return data
    }

    consultarInvitado = async (hash) => {
        const invitadoPrincipal = await this.consultarConHash(hash)
        let invitados = []
        if(invitadoPrincipal[0] && invitadoPrincipal[0].id) {
            invitados = await this.consultarAsociados(invitadoPrincipal[0].id, hash)
            invitados = invitados.map(inv => {
                if(inv.id===invitadoPrincipal[0].id) {
                    inv=invitadoPrincipal[0]
                }
                return inv
            })
            if (typeof(invitados) === "object") {
                await this.setInvitados(invitados)
            }
        }
        return invitados
    }

    setUser = (user) => {
        this.setState({
        user
        })
    }

    setInvitados = (invitados) => {
        this.setState({
        invitados
        })
    }

    setEvent = (eventSelected) => {
        this.setState({
        eventSelected
        })
    }

    setError = (errorEvent) => {
        this.setState({
        errorEvent
        })
    }

    //INICIALIZA CON PÁGINA DE LOGIN Y GUARDA REGISTRO DEL HISTORIAL
    async componentDidMount(){
        if(localStorage.token) {
        const params = new URLSearchParams(window.location.search)
        const evento = params.get('evento')
        await this.setState({
            eventInicial: parseInt(evento)
        })
        this.setLoading(true)
        await this.consultarUsuario()
        }
        await this.history.listen((location) => {
            this.setState({
                path: window.location.pathname
            })
        })
    }

    render() {
        const { loading, eventSelected, user, errorEvent } = this.state

        return(
        <div className={"App " + (this.state.background) }>
            <Router >
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/`} render={() => 
                <div className="App-header"> 
                    {/* SI HAY LOGIN MUESTRA EL LOGIN */}
                    {loading && 
                    <Spinner 
                    background={this.state.background}
                    />
                    }
                    {user && (user.user_edpro || user.hash) ? 
                    <Landing 
                        error={errorEvent}
                        user={this.state.user} 
                        eventSelected={eventSelected}
                        invitados={this.state.invitados || undefined} 
                        loading={this.state.loading}
                        loggingOut={this.loggingOut} 
                        setLoading={this.setLoading}
                        setEvent={this.setEvent}
                        consultarUsuario={this.consultarUsuario} 
                        consultarInvitado={this.consultarInvitado} 
                        setBackground={this.setBackground}
                    />: 
                    <LoginScreen setEvent={this.setEvent} setError={this.setError} loading={this.state.loading} parentContext={this} path={window.location.pathname} user={this.state.user} setUser={this.setUser} consultarUsuario={this.consultarUsuario} consultarInvitado={this.consultarInvitado} setLogin={this.setLogin} setLanding={this.setLanding} setLoading={this.setLoading} loggingOut={this.loggingOut} setBackground={this.setBackground} />
                    }
                </div>} 
                />
            </Switch>
            </Router>
        </div>
        )
    }
}
