import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import urlServer from './server'
import { Link } from 'react-router-dom'
// import Spinner from './spinner'
import brandLogo from './assets/brandlogo.png'
import usuarioSVG from './assets/usuario.svg'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)


export default class Recuperar extends Component {
    constructor(props){
        super(props);
        this.state={
            password:'',
            loading: true,
            alert: [],
            successRecover: false,
        }
        this.submit = this.submit.bind(this)
    }

    // Validador del usuario y contraseña

    validateReq = (data) => {
        let alert = []
        let valid = true
        if (!data.email) {
            alert = [
                ...alert,
                'Debe ingresar un e-mail',
            ]
            valid = false
        }
        if (!valid) {
            this.setState({
                alert
            })
            let string = alert.map(al => {
                return al + "<br />"
            })
            MySwal.fire({
                icon: 'error',
                title: string ? string : "Ha ocurrido un error.",
                showConfirmButton: false,
                timer: 1500
            })
        }
        return valid
    }

    //SUBMIT RECUPERACIÓN
    async submit(event){
        this.setState({
            loading: true
        })
        const data = {
            "email": this.state.email
        }
        if(this.validateReq(data)) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
            const apiBaseUrl = urlServer + `/api/hashGenerate`
            await fetch(apiBaseUrl, {
                method: 'POST',
                redirect: 'manual',
                body: JSON.stringify(data),
                headers
            }).then(async response => {
                if (parseInt(response.status) === 200) {
                    return response.json()
                } else {
                    let err = await response.json()
                    err.status = response.status
                    throw err
                }
            })
            .then(async result => {
                if(result.indexOf('incorrect') === -1) {
                    this.setState({
                        loading: false
                    })
                    MySwal.fire({
                        icon: 'success',
                        title: "Su solicitud se ha enviado con éxito",
                        showConfirmButton: false,
                        timer: 3500
                    })
                    .then(e => {
                        window.location.reload()
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    MySwal.fire({
                        icon: 'error',
                        title: typeof(result) === "string" ? result : "Ha ocurrido un error.",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(error => {
                MySwal.fire({
                    icon: 'error',
                    title: error.message ? error.message : "Ha ocurrido un error.",
                    showConfirmButton: false,
                    timer: 1500
                })
                console.log('error', error); 
                this.setState({
                    loading: false
                })
            });
        }
        this.setState({
            loading: false
        })
    }

    //ESCUCHA LA TECLA "ENTER" PARA HACER EL LOGIN
    enterKeySubmit = (e) => {
        if (e.which === 13) {
            this.submit(e)
        }
    }

    componentDidMount() {
        this.setState({
            loading: false,
        })
    }

    render() {

        return (
                        <React.Fragment>

                            <div className="login-title" >
                                    <img className="brand-logo mb-5" src={brandLogo} alt="brand-logo" ></img>
                                    <div className="f-16" style={{marginBottom: "40px", lineHeight: "20px"}}>
                                        Complete los campos para recibir un correo de recuperación de contraseña. 
                                    </div>
                            </div>
                            
                            {/* BLOQUE DE LOGIN */}
                            {/* {loading ?
                            <div style={{minHeight: '100px'}}>
                                <Spinner />
                            </div>
                            :
                            } */}
                            <div id="">

                                <div className="theme-input col-9 d-flex">
                                    <ReactSVG src={usuarioSVG} className="px-2 svg" />
                                    <input type="email" id="email" name="email" className='theme-input-box' value={this.state.email} placeholder={"E-mail"} autoComplete='on' onChange={(e) => this.setState({ email: e.target.value })} />
                                </div>

                                <div className="col-9 div-button" >
                                    <button className="theme-button f-14 fw-500" onClick={this.submit}>
                                    ENVIAR
                                    </button>
                                </div>
                                <hr style={{borderTopColor: 'white'}} />
                                <div className="d-flex justify-content-between remember flex-row-reverse">
                                    <Link to={"./"}>
                                        <div>
                                            Volver a la pantalla de Login
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {/* // BLOQUE DE LOGIN // */}
                            
                        </React.Fragment>
        );
    }
}